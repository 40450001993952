// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/provider/components/Header";

// icons
import RequestPageIcon from '@mui/icons-material/RequestPage';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { showProvider, updateProgressProvider } from "controllers/providers";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Snackbar, Alert } from '@mui/material';

const ProviderDetail = () => {
  const urlKubera = process.env.REACT_APP_DOMAIN_KUBERA;

  const { id } = useParams();
  const [provider, setProvider] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [documentRutModalOpen, setDocumentRutModalOpen] = useState(false);
  const [documentRutUrl, setDocumentRutUrl] = useState("");
  const [documentBankModalOpen, setDocumentBankModalOpen] = useState(false);
  const [documentBankUrl, setDocumentBankUrl] = useState("");
  const [documentCommerceModalOpen, setDocumentCommerceModalOpen] = useState(false);
  const [documentCommerceUrl, setDocumentCommerceUrl] = useState("");

  const [name, setName] = useState("");
  const [legalName, setLegalName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [document, setDocument] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [country, setCountry] = useState("");
  const [progress, setProgress] = useState("");
  const [listProgress, setListProgress] = useState("");

  useEffect(() => {
    const fetchProvider = async () => {
      const response = await showProvider(id);
      if (response.success) {
        const providerData = response.data.provider;
        setProvider(providerData);
        setName(providerData.name);
        setCategory(providerData.internalCategoryId);
        setLegalName(providerData.legalName);
        setPhone(providerData.phone);
        setEmail(providerData.email);
        setStatus(providerData.status);
        setDocument(providerData.document);
        setTypeDocument(providerData.typeDocument);
        setCountry(providerData.country);
        setProgress(providerData.progress);
        setListProgress(providerData.progress);
        setAttachmentUrl(providerData.attachments?.url);
        setDocumentRutUrl(providerData.documentRut?.url);
        setDocumentBankUrl(providerData.documentBank?.url);
        setDocumentCommerceUrl(providerData.documentCommerce?.url);
      } else {
        setErrors({ fetchError: response.errors });
      }
    };

    fetchProvider();
  }, [id]);

  const handleLockToggle = () => {
    setIsEditMode(!isEditMode);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' o 'error'
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSaveChanges = async () => {
    const { success, errors } = await updateProgressProvider(id, { progress: listProgress, is_active: true });

    if (success) {
      setSuccess(true);
      setErrors({});
      window.location.reload();
      // Mostrar alerta de éxito
      setSnackbarSeverity('success');
      setSnackbarMessage('Cambios guardados exitosamente.');
      setSnackbarOpen(true);
    } else {
      setErrors(errors);
      // Mostrar alerta de error
      setSnackbarSeverity('error');
      setSnackbarMessage('Hubo un error al guardar los cambios. Por favor, inténtalo de nuevo.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const providers = {
    // ...otros campos existentes...
    progressOptions: [
      { value: "aprobado", label: "Aprobado" },
      { value: "reprobado", label: "Reprobado" },
      { value: "solicitado", label: "Solicitado" },
      { value: "revision", label: "Revision" }
      // Agrega más opciones de progreso según tus necesidades
    ],
  };

  const handleOpenAttachmentModal = () => {
    setAttachmentModalOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setAttachmentModalOpen(false);
  };

  const handleOpenDocumentRutModal = () => {
    setDocumentRutModalOpen(true);
  };

  const handleCloseDocumentRutModal = () => {
    setDocumentRutModalOpen(false);
  };

  const handleOpenDocumentBankModal = () => {
    setDocumentBankModalOpen(true);
  };

  const handleCloseDocumentBankModal = () => {
    setDocumentBankModalOpen(false);
  };

  if (!provider) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div>Loading...</div>
      </DashboardLayout>
    );
  }

  // Función para determinar el color según el estado
  const getColorByProgress = (progress) => {
    switch (progress) {
      case 'aprobado':
        return 'green';
      case 'solicitado':
        return 'yellow';
      case 'revision':
        return 'orange';
      case 'reprobado':
        return 'red';
      default:
        return 'black'; // Color predeterminado en caso de no coincidir ningún estado
    }
  };

  // Obtén el color según el estado actual
  const progressColor = getColorByProgress(progress);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={8} sx={{ display: "flex" }}>
              <MDTypography variant="h5" fontWeight="medium">Proveedor</MDTypography>
              <ProfileInfoCard
                info={{
                  fullName: name,
                  razonSocial: legalName,
                  location: country,
                  category: category,
                  docuemtoType: typeDocument,
                  document: document,
                  phone: phone,
                  email: email,

                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <MDTypography variant="h5" fontWeight="medium">
                Documents
              </MDTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={12}>
                  <MDButton
                    component="label"
                    variant="outlined"
                    color="black"
                    style={{ height: 150, width: 200, border: '1px solid white', backgroundColor: "#344767", top: "50%" }}
                    onClick={handleOpenAttachmentModal}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <AccountCircleIcon style={{ transform: 'scale(4)', color: 'white', radius: 20 }} />
                      <Divider />
                      <MDTypography variant="h6" fontWeight="medium" color="white"> Documento </MDTypography>
                    </div>
                  </MDButton>

                  <Modal
                    open={attachmentModalOpen}
                    onClose={handleCloseAttachmentModal}
                    aria-labelledby="attachment-modal-title"
                    aria-describedby="attachment-modal-description"
                  >
                    <MDBox
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 2

                      }}
                    >
                      <MDTypography variant="h6" id="attachment-modal-title">
                        Documento del representante legal
                      </MDTypography>
                      <iframe src={attachmentUrl} alt="Attachment" style={{ width: '100%', height: '95%', borderRadius: 6 }} />
                    </MDBox>
                  </Modal>
                </Grid>

                <Grid item xs={12} xl={12}>
                  <MDButton
                    component="label"
                    variant="outlined"
                    color="black"
                    style={{ height: 150, width: 200, border: '1px solid white', backgroundColor: "#344767", top: "50%" }}
                    onClick={handleOpenDocumentRutModal}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <FindInPageIcon style={{ transform: 'scale(4)', color: 'white', radius: 20 }} />
                      <Divider />
                      <MDTypography variant="h6" fontWeight="medium" color="white"> RUT </MDTypography>
                    </div>
                  </MDButton>
                  <Modal
                    open={documentRutModalOpen}
                    onClose={handleCloseDocumentRutModal}
                    aria-labelledby="document-rut-modal-title"
                    aria-describedby="document-rut-modal-description"
                  >
                    <MDBox
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 2
                      }}
                    >
                      <MDTypography variant="h6" id="document-rut-modal-title">
                        Documento RUT
                      </MDTypography>
                      <iframe src={documentRutUrl} alt="Document RUT" style={{ width: '100%', height: '95%', borderRadius: 6 }} />
                    </MDBox>
                  </Modal>
                </Grid>


                <Grid item xs={12} xl={12}>
                  <MDButton
                    component="label"
                    variant="outlined"
                    color="black"
                    style={{ height: 150, width: 200, border: '1px solid white', backgroundColor: "#344767", top: "50%" }}
                    onClick={handleOpenDocumentBankModal}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <RequestPageIcon style={{ transform: 'scale(4)', color: 'white', radius: 20 }} />
                      <Divider />
                      <MDTypography variant="h6" fontWeight="medium" color="white"> Crt. Bancario </MDTypography>
                    </div>
                  </MDButton>
                  <Modal
                    open={documentBankModalOpen}
                    onClose={handleCloseDocumentBankModal}
                    aria-labelledby="document-bank-modal-title"
                    aria-describedby="document-bank-modal-description"
                  >
                    <MDBox
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 2
                      }}
                    >
                      <MDTypography variant="h6" id="document-bank-modal-title">
                        Documento Bancario
                      </MDTypography>
                      <iframe src={documentBankUrl} title="Document Bank" style={{ width: '100%', height: '95%', borderRadius: 6, border: 'none' }} />
                    </MDBox>
                  </Modal>
                </Grid>
                
                <Grid item xs={12} xl={12}>
                  <MDButton
                    component="label"
                    variant="outlined"
                    color="black"
                    style={{ height: 150, width: 200, border: '1px solid white', backgroundColor: "#344767", top: "50%" }}
                    onClick={handleOpenDocumentCommerceModal}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <RequestPageIcon style={{ transform: 'scale(4)', color: 'white', radius: 20 }} />
                      <Divider />
                      <MDTypography variant="h6" fontWeight="medium" color="white"> Crt. Bancario </MDTypography>
                    </div>
                  </MDButton>
                  <Modal
                    open={documentCommerceModalOpen}
                    onClose={handleCloseDocumentCommerceModal}
                    aria-labelledby="document-bank-modal-title"
                    aria-describedby="document-bank-modal-description"
                  >
                    <MDBox
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 2
                      }}
                    >
                      <MDTypography variant="h6" id="document-bank-modal-title">
                        Documento Bancario
                      </MDTypography>
                      <iframe src={documentCommerceUrl} title="Document Bank" style={{ width: '100%', height: '95%', borderRadius: 6, border: 'none' }} />
                    </MDBox>
                  </Modal>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <MDTypography fontWeight="medium">
              Estado: <span style={{ color: progressColor }}>{progress}</span>
            </MDTypography>
            <Divider />
            <FormControl fullWidth>
              <MDTypography fontWeight="medium" id="select-progress-label">Actualizar Estado</MDTypography>
              <Select
                variant="standard"
                labelId="select-progress-label"
                required={true}
                id="select-progress"
                value={listProgress}
                onChange={(e) => setListProgress(e.target.value)}
                sx={{ p: 2 }}
              >
                {providers.progressOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checked={isEditMode}
              onChange={handleLockToggle}
              disabled={success}
            />
            <Typography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: "pointer", userSelect: "none" }}
              onClick={handleLockToggle}
              disabled={success}
            >
              {isEditMode ? "Bloquear" : "Desbloquear"}
            </Typography>
          </Grid>
          {isEditMode && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveChanges}
                disabled={success}
              >
                Guardar cambios
              </Button>
            </Grid>
          )}
          {Object.keys(errors).map((fieldName) => (
            <Grid item xs={12} key={fieldName}>
              <Typography variant="body1" color="error">
                {errors[fieldName]}
              </Typography>
            </Grid>
          ))}
          {success && (
            <Grid item xs={12}>
              <Typography variant="body1" color="success">
                Proveedor actualizado exitosamente.
              </Typography>
            </Grid>
          )}
        </MDBox>
      </Header>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Puedes ajustar la duración según tus preferencias
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};

export default ProviderDetail;
