import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";
import { BonusList } from "controllers/bonus";
import { InternalProductList } from "controllers/internal_products";

function OrdersOverview() {
  const [products, setProducts] = useState([]);
  const [bonuses, setBonuses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await InternalProductList();
        const bonusResponse = await BonusList();

        if (productResponse.success && bonusResponse.success) {
          setProducts(productResponse.data?.internalProducts || []);
          setBonuses(bonusResponse.data?.bonus || []);
        } else {
          setProducts([]);
          setBonuses([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setProducts([]);
        setBonuses([]);
      }
    };

    fetchData();
  }, []);

  const calcularGananciaPorProducto = (productos, bonos) => {
    const gananciaPorProducto = {};
    productos.forEach(producto => {
      gananciaPorProducto[producto.id] = {
        nombre: producto.name,
        precio: producto.price,
        canjes: 0,
        total: 0,
      };
    });

    bonos.forEach(bono => {
      const productId = bono.internalProductId;
      gananciaPorProducto[productId].canjes++;
      gananciaPorProducto[productId].total += gananciaPorProducto[productId].precio;
    });

    return gananciaPorProducto;
  };

  const gananciaPorProducto = calcularGananciaPorProducto(products, bonuses);

  const productosOrdenados = Object.values(gananciaPorProducto).sort((a, b) => b.total - a.total);

  const productosTop5 = productosOrdenados.slice(0, 5);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Productos por ganancia
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {productosTop5.map((producto, index) => (
          <TimelineItem
            key={index}
            color="success"
            icon="shopping_cart"
            title={`${producto.nombre}: ${producto.canjes} canjes`}
            dateTime={`Total: $${producto.total}`}
            lastItem={index === productosTop5.length - 1 || index === Math.min(4, productosOrdenados.length - 1)}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
