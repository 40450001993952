import MDButton from "components/MDButton";
import React from "react";


function LogoutButton() {
    const handleLogout = () => {
        // elimina el token y otra información del usuario almacenada en localStorage
        localStorage.clear();
        //REDIRIGIR AL LOGIN
        window.location.href = "/";
        // recarga la página para actualizar la interfaz de usuario
        // window.location.reload();
    };

    return (
        <MDButton
                rel="noreferrer"
                color="dark"
                onClick={handleLogout}
              >
                {/* <TwitterIcon /> */}
                &nbsp; Cerrar sesion
              </MDButton>
    );
}

export default LogoutButton;