import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { InternalCatalogList } from '../../controllers/internal_catalogs';
import { InternalProductListAll, updateProduct, ApproveProduct, ApprovateProduct, approveProduct } from '../../controllers/internal_products';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import HttpRequest from '../../services/http_request';
import { CheckBox } from '@mui/icons-material';

const Multicatalogo = () => {
    const [catalogs, setCatalogs] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState(null);

    const [id, setId] = React.useState(null);
    const [aprobado, setAprobado] = React.useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await InternalCatalogList();
                if (response.success) {
                    setCatalogs(response.data.internalCatalogs);
                } else {
                    console.error('Error al obtener los catálogos');
                }
            } catch (error) {
                console.error('Error al obtener los catálogos', error);
            }
        };

        fetchData();
    }, []);

    const handleVerMasClick = async (catalog) => {
        setSelectedCatalog(catalog);
        setOpenModal(true);

        try {
            const response = await InternalProductListAll();
            if (response.success) {
                // Filtra los productos para mostrar solo los asociados al catálogo seleccionado
                const filteredProducts = response.data.internalProducts.filter(
                    (product) => product.internalCatalogId === catalog.id
                );
                setProducts(filteredProducts);
            } else {
                setProducts([]);
                console.error('Error al obtener los productos de la marca' + response.data);
            }
        } catch (error) {
            console.error('Error al obtener los productos de la marca(catch)', error);
        }
    };

    const handleApprove = async (productId) => {
        const result = await approveProduct(productId);

        if (result.success) {
            alert('Producto aprobado correctamente');
            // Realiza otras acciones si es necesario
            handleVerMasClick(selectedCatalog);
        } else {
            alert('Error al aprobar el producto');
            // Maneja el error de acuerdo a tus necesidades
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {catalogs && catalogs.length > 0 ? (
                    catalogs.map((catalog) => (
                        <Card key={catalog.id} variant="outlined" style={{ margin: '10px', minWidth: '200px' }}>
                            <CardContent>
                                <MDTypography variant="h4">{catalog.name}</MDTypography>
                                <MDTypography variant="h6">ID: {catalog.id}</MDTypography>
                                <MDTypography variant="h6">Creado en: {catalog.createdAt}</MDTypography>
                                <MDTypography variant="h6">Actualizado en: {catalog.updatedAt}</MDTypography>
                                <Button variant="" style={{ color: "black" }} onClick={() => handleVerMasClick(catalog)}>Ver Más</Button>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <MDTypography>No se encontraron catálogos.</MDTypography>
                )}
            </MDBox>

            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>
                    {selectedCatalog && (
                        <>
                            {selectedCatalog.name}#{selectedCatalog.id}
                        </>
                    )}
                </DialogTitle>
                <DialogContent className="product_modal">
                    {selectedCatalog && (
                        <>
                            {/* <p>Nombre: {selectedCatalog.name}</p>
                            <p>ID: {selectedCatalog.id}</p> */}
                            {/* <MDBox className="organice_product"> */}
                            {products && products.length > 0 ? (
                                products.map((product) => (
                                    <MDBox
                                        key={product.id} // Asegúrate de usar una clave única para cada MDBox
                                        mx={2} s
                                        mt={2}
                                        py={1.5}
                                        px={1.5}
                                        variant="gradient"
                                        coloredShadow="info"
                                        className={product.isActive ? "card_form_product" : "card_form_product_no_activate"}
                                    >

                                        <p>{product.name}</p>
                                        <p>{product.id}</p>
                                        <p>Precio: {product.price}</p>
                                        <p>Stock: {product.stock}</p>
                                        <p>estdo: {product.isActive.toString()}</p>
                                        <Button
                                            variant="outlined"
                                            color={product.isActive ? "dark" : "primary"}
                                            onClick={() => handleApprove(product.id)}
                                        >
                                            {product.isActive ? "Desactivar" : "Activar"}
                                        </Button>
                                        {/* Agrega más detalles del producto según tus necesidades */}
                                    </MDBox>

                                ))
                            ) : (
                                <p>No hay productos disponibles para esta marca.</p>
                            )}
                            {/* </MDBox> */}
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default Multicatalogo;
