import React, { useEffect, useRef } from 'react';

import { BonusList } from '../../../controllers/bonus';


export default function DataBonus() {
  const dataFetchedRef = useRef(false);
  const [bonus, setBonus] = React.useState([]);

  // Se consulta la lista de bonos generados y se procesan los datos
  // de forma que se agrupen la cantidad de bonos generados por mes
  const fetchBonusAll = async () => {
    const { success, data, errors } = await BonusList();

    if (success) {
      const bonusWithMonth = data.bonus.map((bonus) => {
        const fecha = new Date(Date.parse(bonus.createdAt));
        const mes = fecha.getMonth() + 1;
        return { ...bonus, createdAt: mes };
      });

      const bonusByMonth = bonusWithMonth.reduce((acc, bonus) => {
        const mes = bonus.createdAt;
        if (!acc[mes]) {
          acc[mes] = [];
        }
        acc[mes].push(bonus);

        return acc;

      }, {});

      setBonus(bonusByMonth);
    }

  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchBonusAll();
  }, [fetchBonusAll]);


  // Se genera una constante con los meses
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  //se creo el objeto que alamcenara los datos
  const data = {
    labels: [],
    datasets: {
      label: 'Bonos',
      data: []
    }
  };
  
  //Se genera este listado para almacenar los meses que contengan data y evitar mostrar todos
  const monthData = [];
  
  Object.keys(bonus).forEach((key) => {
    const date = bonus[key][0].createdAt;
    const monthIndex = date - 1;
    if (bonus[key].length > 0) {
      monthData.push({month: monthIndex, value: bonus[key].length});
    }
  });
  
  monthData.forEach((month) => {
    data.labels.push(monthNames[month.month]);
    data.datasets.data.push(month.value);
  });

  return data;  

} 
