import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import 'components/styles/login/login.scss';
import { SignIn } from '../../../controllers/providers';
import kubera1 from '../../../assets/images/kubera_login_1.jpeg';
import kubera_icon from '../../../assets/images/kubera_login_icono.png';
import MessageModal from "components/Notification";

function Basic() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangePassword = (event) => setPassword(event.target.value);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { success, errors } = await SignIn({ email, password });

    if (!success) {
      setModalMessage('El correo o la contraseña son incorrectos');
      setShowModal(true);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
        <div className="container">
          <div className="card login-card">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={kubera1} alt="login" className="login-card-img" />
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <div className="brand-wrapper">
                    <img src={kubera_icon} alt="logo" className="logo" />
                  </div>
                  <p className="login-card-description">Portal de Aliados</p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <p>Correo</p>
                      <label htmlFor="email" className="sr-only">Correo</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Correo electrónico"
                        onChange={handleChangeEmail}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <p>Contraseña</p>
                      <label htmlFor="password" className="sr-only">Contraseña</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="***********"
                        onChange={handleChangePassword}
                      />
                    </div>
                    <div className="form-group mb-4 d-flex justify-content-between">
                      <input
                        name="login"
                        id="login"
                        className="btn btn-block login-btn mb-4"
                        type="submit"
                        value="Iniciar Sesión"
                      />
                      <a href="#!" className="forgot-password-link">¿Olvidaste tu contraseña?</a>
                    </div>
                  </form>
                  <p className="login-card-footer-text">
                    ¿Quieres ser parte de Kubera? <Link to="/register" className="link_register">Regístrate aquí</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <MessageModal isOpen={showModal} message={modalMessage} onClose={handleCloseModal} />
    </div>
  );
}

export default Basic;
