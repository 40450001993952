import HttpRequest from '../../services/http_request';
import lscache from 'lscache';
import { AUTH_TOKEN, AUTH_ROLE } from './constants';

{/* Función para iniciar sesión en la plataforma */ }

export const SignIn = async (credentials) => {
  try {
    //console.log(credentials.email, credentials.password);
    const resp = await HttpRequest({
      method: 'POST',
      url: '/api/v4/provider/sign_in',
      data: {
        email: credentials.email,
        password: credentials.password
      }
    });
    lscache.set(AUTH_TOKEN, resp.data.token);
    lscache.set(AUTH_ROLE, btoa(resp.data.provider.role));
    lscache.set('current_provider', btoa(resp.data.provider.id));
    lscache.set('progress_status', btoa(resp.data.provider.progress));

    return {
      success: true
    }
  } catch (error) {
    return {
      success: false,
      errors: 'Ingrese un correo y contraseña validos por favor.'
    }
  }
};

{/* Función para ver los datos de un proveedor */ }

export const showProvider = async (id) => {
  try {
    const resp = await HttpRequest({
      method: 'GET',
      url: `/api/v4/providers/providers/${id}`
    });
    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: 'Hubo un error al obtener los datos del proveedor.'
    };
  }
};

{/* Función para actualizar un proveedor */ }

export const updateProvider = async (id, fields) => {
  // Utilizar el formData de createApplicant
  // alert(fields.internalCategoryId)
  try {
    const formData = new FormData();
    formData.append("provider[user_name]", fields.userName);
    formData.append("provider[attachments]", fields.documentUploaded);
    formData.append("provider[document_rut]", fields.rutUploaded);
    formData.append("provider[document_bank]", fields.bankUploaded);
    formData.append("provider[document_commerce]", fields.commerceUploaded);
    formData.append("provider[country]", fields.country);
    formData.append("provider[type_document]", fields.typeDocument);
    formData.append("provider[document]", fields.document);
    formData.append("provider[progress]", fields.progress);
    formData.append("provider[logo]", fields.logo);
    formData.append("provider[expired]", fields.expirationDate);
    formData.append("provider[internal_category_id]", fields.internalCategoryId);

    const resp = await HttpRequest({
      method: 'PUT',
      url: '/api/v4/providers/providers/' + id,
      data: formData
    });

    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: error
    };
  }
};

export const updateProvider_2 = async (id, fields) => {
  // Utilizar el formData de createApplicant
  // alert(fields.logo)
  try {
    const formData = new FormData();
    formData.append("provider[name]", fields.name);
    formData.append("provider[attachments]", fields.documentUploaded);
    formData.append("provider[document_rut]", fields.rutUploaded);
    formData.append("provider[document_bank]", fields.bankUploaded);
    formData.append("provider[document_commerce]", fields.commerceUploaded); // Agregado
    formData.append("provider[country]", fields.country);
    formData.append("provider[type_document]", fields.typeDocument);
    formData.append("provider[document]", fields.document);
    formData.append("provider[phone]", fields.phone);
    formData.append("provider[progress]", fields.progress);
    formData.append("provider[logo]", fields.logo);
    formData.append("provider[expired]", fields.expirationDate);
    formData.append("provider[internal_category_id]", fields.internalCategoryId);

    const resp = await HttpRequest({
      method: 'PUT',
      url: '/api/v4/providers/providers/' + id,
      data: formData
    });

    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: error
    };
  }
};

export const updateProgressProvider = async (id, fields) => {
  // Utilizar el formData de createApplicant
  // alert(fields.progress)
  try {
    const formData = new FormData();
    formData.append("provider[progress]", fields.progress);

    const resp = await HttpRequest({
      method: 'PUT',
      url: '/api/v4/providers/providers/' + id,
      data: formData
    });

    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: error
    };
  }
};

{/* Función para registrar un proveedor */ }

export const createApplicant = async (fields) => {
  try {
    const formData = new FormData();
    formData.append("applicant[name]", fields.name);
    formData.append("applicant[legal_name]", fields.legalName);
    formData.append("applicant[phone]", fields.phone);
    formData.append("applicant[email]", fields.email);
    formData.append("applicant[password]", fields.password);
    formData.append("applicant[uid]", null);
    formData.append("applicant[provider]", null);
    //formData.append("applicant[attachments]", fields.attachments);

    const resp = await HttpRequest({
      method: 'POST',
      url: '/api/v4/providers/applicants',
      data: formData
    });

    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: error
    }
  }
};

{/* Función para listar un proveedor */ }

export const ProvidersList = async (credentials) => {
  try {
    const resp = await HttpRequest({
      method: 'GET',
      url: '/api/v4/providers/providers',
    });

    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: 'Ingrese un correo y contraseña validos por favor.'
    }
  }

};

{/* Función para realizar llamado de un proveedor (para validaciones internas) */ }

export const getProvider = async (id) => {
  try {
    const resp = await HttpRequest({
      method: 'GET',
      url: '/api/v4/providers/providers/' + id,
    });

    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: 'No hay usuario'
    }
  }
};

{/* Función para aprobar un proveedor */ }

export const ApprovateApplicant = async (id) => {
  try {
    const resp = await HttpRequest({
      method: 'POST',
      url: `/api/v4/providers/applicants/${id}/approvate`,
    });

    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: error
    }
  }
};