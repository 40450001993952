import React, { useEffect, useRef } from 'react';

import { ProvidersList } from '../../../controllers/providers';

export default function DataProvider() {
    const dataFetchedRef = useRef(false);
    const [provider, setProvider] = React.useState([]);

    // Se consulta la lista de bonos generados y se procesan los datos
    // de forma que se agrupen la cantidad de bonos generados por mes
    const fetchProviderAll = async () => {
        const { success, data, errors } = await ProvidersList();

        if (success) {
            setProvider(data);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        fetchProviderAll();
    }, [fetchProviderAll]);

    return provider;
}
