import React from 'react';
import lscache from 'lscache';
import { Navigate } from 'react-router-dom';
import { AUTH_TOKEN, AUTH_ROLE } from '../controllers/providers/constants';

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
    const isLogged = lscache.get(AUTH_TOKEN);
    const role = atob(lscache.get(AUTH_ROLE));
    

    if (!!isLogged) {
        if (allowedRoles.includes(role)) {
            if (role === 'user') {
                window.location.href = "/";
                return <Navigate to="/dashboard" />;

            } else if (role === 'admin') {
                window.location.href = "/";
                return <Navigate to="/provider" />;
            }
        } else if (role === 'user') {
            window.location.href = "/";
            return <Navigate to="/dashboard" />;
        } else if (role === 'admin') {
            window.location.href = "/";
            return <Navigate to="/provider" />;
        } 
    } else {
        window.location.href = "/";
        return <Navigate to="/" />;
    }

}

export default PrivateRoute;