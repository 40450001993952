import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { ApprovateApplicant, ProvidersList } from '../../controllers/providers'
//import categoryData from "layouts/category/data/categoryData";

import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";


const Provider = () => {
    const [id, setApplicantId] = useState(null);
    const [providers, setProviders] = useState([])
    const [openReposiveDialog, setOpenReposiveDialog] = React.useState(false);
    const [name, setName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [document, setDocument] = React.useState(null);
    const [typeDocument, setTypeDocument] = React.useState(null);
    const [legalName, setLegalName] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [attachments, setAttachments] = React.useState(null);
    const [progress, setProgress] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [providerDetail, setProviderDetail] = React.useState("");
    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);
    
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };

    
    const rowHandler = (event, row) => {
      setApplicantId(row.id);
      setDocument(row.document);
      setName(row.name);
      setEmail(row.email);
      setCountry(row.country);
      setTypeDocument(row.typeDocument);
      setLegalName(row.legalName);
      setPhone(row.phone);
      setStatus(row.status);
      setAttachments(row.attachments);
      setProgress(row.progress);
      setOpenReposiveDialog(true);
    }
    
    function preventDefault(event) {
      event.preventDefault();
    }

    const fetchProviders = async() => {
      const { success, data, errors } = await ProvidersList();
      
      if (success) {
        setProviders(data.providers);
      } else {
        // alert('No hay data');
      }
    }

    const filteredProviders = providers.filter((provider) => {
      return provider.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    
    const handleSave = async(id) => {
      const { success, data } = await ApprovateApplicant(id)
      if (success){

        fetchProviders();
        setOpenReposiveDialog(false);
      } else {

      }
      
    }

    useEffect(() => {
      fetchProviders();
    }, [])
    
    // Manejar el evento de crear una nueva categoría
    const handleNewCategorie = () => {
      setOpenReposiveDialog(true);
    };

    const showHandler = (id) => {
      navigate(`/provider/${id}`)
    }

    const prepareTableData = (providers) => {
    const rows = providers.map(({ id, name, legalName, progress }) => ({
      id,
      name,
      legalName,
      progress,
      action: (
        <div>
          {/* Aquí puedes agregar los componentes o elementos de acción */}
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderStartStartRadius: '15px',
            borderBottomLeftRadius: '15px'
          }} onClick={() => showHandler(id)}>Ver</button>
          <span style={{ color: 'black', padding: '10px 5px', background: '#EFEFEF' }}>•</span>
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderEndEndRadius: '15px',
            borderStartEndRadius: '15px'
          }} onClick={() => handleSave(id)}>Aprobar</button>
        </div>
      )
      
    }));

  
    return {
      columns: [
        { Header: "Id", accessor: "id", width: "5%", align: "left" },
        { Header: "Nombre", accessor: "name", width: "25%", align: "left" },
        { Header: "Razon social", accessor: "legalName", align: "center" },
        { Header: "Estado", accessor: "progress", align: "center" },
        { Header: "Acciones", accessor: "action", align: "center" },

      ],
      rows: rows,
    };
  };

  const { columns, rows } = useMemo(() => prepareTableData(providers), [providers]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                coloredShadow="warning"
                className="card_form"
              >
                <MDTypography className="button_form_cards_title" variant="p" color="white" pb={1}>
                  Proveedores
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  canSearch={true}
                  // showTotalEntries={true}
                  noEndBorder
                  rowHandler={rowHandler}
                  handleSearch={handleSearch}
                  searchTerm={searchTerm}
                  // filteredData={filteredCategories}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

    </DashboardLayout>
  );
};

export default Provider;
