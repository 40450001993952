
  
  // @mui material components
  import Grid from "@mui/material/Grid";

  // Material Dashboard 2 React components
  import MDBox from "components/MDBox";

  // Material Dashboard 2 React example components
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import Footer from "examples/Footer";
  import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
  import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
  import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

  // Data
  import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";

  // Dashboard components
  import Projects from "layouts/admin_dashboard/components/Projects";
  import OrdersOverview from "layouts/admin_dashboard/components/OrdersOverview";
  import DataBonus from 'layouts/admin_dashboard/data/dataBonus';
  import DataCash from 'layouts/admin_dashboard/data/dataCash';
  import DataSells from 'layouts/admin_dashboard/data/dataSells';
  import DataProvider from 'layouts/admin_dashboard/data/dataProvider';

  import React from "react";

  function ProviderLayout() {
    // const { sales, tasks } = reportsLineChartData;

    const dataCash  = DataCash();
    const dataBonus = DataBonus();
    const dataSells = DataSells();
    const dataProvider = DataProvider();
    
    try {
      var providerCount = dataProvider.providers.length;
      // console.log(providerCount)
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error de la manera que prefieras
    }
    const today = new Date();
    const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const lastMonthName = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(new Date(today.getFullYear(), lastMonth));

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="person_pin_icon"
                  title="Proveedores activos"
                  count={providerCount}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "este año",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="leaderboard"
                  title="Actividad"
                  count="60 Redenciones"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "que el mes pasado",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="store"
                  title="Ingreso promedio/bono"
                  count="24.000$"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="report"
                  title="Tickets"
                  count="+10"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "10 casos activos",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="warning"
                    title="Interaccion de proveedores"
                    // description="Last Campaign Performance"
                    date={`actualizado el mes de ${lastMonthName}`}
                    chart={reportsBarChartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Bonos generados por proveedor"
                    // description={
                    //   <>
                    //     (<strong>+15%</strong>) increase in today sales.
                    //   </>
                    // }
                    date={`actualizado el mes de ${lastMonthName}`}
                    chart={dataBonus}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="warning"
                    title="Ventas mensuales "
                    // description="Last Campaign Performance"
                    date={`actualizado el mes de ${lastMonthName}`}
                    chart={dataCash}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Projects />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OrdersOverview />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    );
  }

  export default ProviderLayout;
