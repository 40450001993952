import React, { useEffect, useRef } from 'react';

import { BonusList } from '../../../controllers/bonus';
import { InternalProductList } from '../../../controllers/internal_products';

export default function DataCash() {
  const dataFetchedRef = useRef(false);
  const [bonus, setBonus] = React.useState([])
  const [productList, setProductList] = React.useState([]);

  // Se consulta la lista de bonos generados y se procesan los datos
  // de forma que se agrupen la cantidad de bonos generados por mes
  const fetchBonusAll = async() => {
    const { success, data, errors } = await BonusList();
    if (success) {

      setBonus(data.bonus);
    } else {
      // alert('No hay data');
    }
  }

  const fetchInternalProductList = async () => {
    const { success, data, errors } = await InternalProductList();

    if (success) {
      setProductList(data.internalProducts);
    } else {
      // alert('No hay data');
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchInternalProductList();
    fetchBonusAll();
  }, [fetchBonusAll, fetchInternalProductList]);

  const updatedBonus = bonus.map((bonusObj) => {
    const matchingProduct = productList.find(
      (productObj) => productObj.id === bonusObj.internalProductId
    );
    if (matchingProduct === undefined) {
      return bonusObj;
    }
    return {
      ...bonusObj,
      price: matchingProduct.price,
    };
  });

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  const groupedByMonth = updatedBonus.reduce((acc, curr) => {
    const date = new Date(curr.createdAt);
    const month = monthNames[date.getMonth()];
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(curr);
    return acc;
  }, {});
  
  const sortedGroupedByMonth = Object.keys(groupedByMonth)
  .sort((a, b) => monthNames.indexOf(a) - monthNames.indexOf(b))
  .reduce((acc, key) => {
    const total = groupedByMonth[key].reduce((sum, item) => sum + (isNaN(parseInt(item.price)) ? 0 : parseInt(item.price)), 0);
    acc[key] = total;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(sortedGroupedByMonth).map(month => monthNames[monthNames.indexOf(month)]),
    datasets: {
      label: 'Bonos',
      data: Object.values(sortedGroupedByMonth)
    }
  };

  return data;  

}
