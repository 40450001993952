import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { SvgIcon } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { InternalProductList } from '../../controllers/internal_products';
import { BonusWallet } from 'controllers/bonus';
import { BonusList } from '../../controllers/bonus';

const Wallet = () => {
    const dataFetchedRef = useRef(false);
    const [wallet, setWallet] = useState({});
    const { total, generated, used } = wallet.data || {};
    const [searchTerm, setSearchTerm] = useState("");
    const [bonus, setBonus] = React.useState([])
    const [productList, setProductList] = React.useState([]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchBonusWallet = useCallback(async () => {
        const { success, data, error } = await BonusWallet();
        if (success) {
            setWallet(data);
        } else {
            console.log(error);
        }
    }, []);

    const fetchBonusAll = async () => {
        const { success, data, errors } = await BonusList();
        if (success) {

            setBonus(data.bonus);
        } else {
            // alert('No hay data');
        }
    }

    useEffect(() => {
        // Obtener la lista de productos internos desde la API
        const fetchInternalProductList = async () => {
            const { success, data, errors } = await InternalProductList();

            if (success) {
                setProductList(data.internalProducts);
            } else {
                // alert('No hay data');
            }
        };

        fetchInternalProductList();
    }, []);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        fetchBonusWallet();
        fetchBonusAll();
    }, [fetchBonusWallet]);

    const prepareTableData = (bonus, productList) => {
        const rows = bonus.map(({ id, uuid, internalProductId, createdAt, status }) => ({
            id,
            uuid,
            internalProductId: productList.find((product) => product.id === internalProductId)?.name || 'Vacío',
            createdAt,
            status: status !== 'used' ? 'activo' : 'Completado',
            // backgroundColor: status !== 'used' ? 'green' : 'red', // Agregamos una propiedad para el color de fondo
        }));

        return {
            columns: [
                { Header: "Id", accessor: "id", width: "10%", align: "left" },
                { Header: "Uuid", accessor: "uuid", width: "25%", align: "left" },
                { Header: "Nombre", accessor: "internalProductId", align: "center" },
                { Header: "Fec. Actualizacion", accessor: "createdAt", align: "center" },
                {
                    Header: "Estado",
                    accessor: "status",
                    align: "center",
                    Cell: ({ value }) => (
                        <div style={{ backgroundColor: value === 'activo' ? '#37be22' : '#fb6666', width: value === 'activo' ? '200%' : '120%', color: 'white', borderRadius: '15px' }}>
                            {value}
                        </div>
                    )
                },
            ],
            rows: rows,
        };
    };

    const { columns, rows } = React.useMemo(() => prepareTableData(bonus, productList), [bonus, productList]);

    const formattedGenerated = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(generated);
    const formattedUsed = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(used);
    const formattedTotal = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(total);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12} spacing={3}>
                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        coloredShadow="warning"
                                        className="card_form"
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <MDBox
                                                    mx={2}
                                                    mt={-3}
                                                    py={3}
                                                    px={2}
                                                    className="money"
                                                >
                                                    <SvgIcon component={AddCircleOutlineIcon} color="white" />
                                                    <MDTypography className="button_form_cards_title" variant="p" color="white" pb={1}>
                                                        Total $ bonos canjeados:
                                                        <br />
                                                        {formattedGenerated}
                                                    </MDTypography>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MDBox
                                                    mx={2}
                                                    mt={-3}
                                                    py={3}
                                                    px={2}
                                                    className="money"
                                                >
                                                    <SvgIcon component={MonetizationOnIcon} color="white" />
                                                    <MDTypography className="button_form_cards_title" variant="p" color="white" pb={1}>
                                                        Generado en bonos:
                                                        <br />
                                                        {formattedUsed}
                                                    </MDTypography>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Grid>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    coloredShadow="warning"
                                    className="card_form"
                                >
                                    <Grid item xs={12} md={3}>
                                        <MDBox
                                            mx={2}
                                            mt={-3}
                                            py={3}
                                            px={2}
                                            className="money"
                                        >
                                            <SvgIcon component={MonetizationOnIcon} color="white" />
                                            <MDTypography className="button_form_cards_title" variant="p" color="white">
                                                Total $
                                                <br />
                                                {formattedTotal}
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </MDBox>
                            </Grid>

                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                    rowHandler={(event, row) => console.log("Row clicked:", row)}
                                    handleSearch={handleSearch}
                                    searchTerm={searchTerm}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
};

export default Wallet;
