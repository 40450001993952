import { useState, useEffect, useRef, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useMaterialUIController, setOpenConfigurator } from "context";
import LogoutButton from "components/Logout";
import { ProviderList, updateProgressProvider, getProvider } from "controllers/providers";
import { useParams } from "react-router-dom";
import { AUTH_ROLE } from 'controllers/providers/constants';
import lscache from "lscache";
import { Checkbox, Grid, Modal, ModalManager } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Modal components
import { FormControl, MenuItem, Select } from '@mui/material';
import MDInput from 'components/MDInput';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InternalCategorieList } from "controllers/internal_category";
import { Typography } from "antd";
import { updateProvider_2 } from "controllers/providers";


function Configurator() {
  const urlKubera = process.env.REACT_APP_DOMAIN_KUBERA;
  const [controller, dispatch] = useMaterialUIController();
  const role = atob(lscache.get(AUTH_ROLE));
  const progress_status = atob(lscache.get('progress_status'));


  const [categories, setCategories] = useState([]);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [confirmCheckbox2, setConfirmCheckbox2] = useState(false);

  const id = atob(lscache.get('current_provider'));
  const [provider, setProvider] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [logo, setLogo] = useState('');
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [documentRutUrl, setDocumentRutUrl] = useState("");
  const [documentBankUrl, setDocumentBankUrl] = useState("");

  const [name, setName] = useState("");
  const [legalName, setLegalName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [document, setDocument] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [country, setCountry] = useState("");
  const [progress, setProgress] = useState("");
  const [listProgress, setListProgress] = useState("");

  const { openConfigurator, fixedNavbar, darkMode } = controller;
  const [disabled, setDisabled] = useState(false);

  // Modal state
  const [openModal, setOpenModal] = useState(false);

  // Modal form state
  const [userName, setUserName] = useState('');
  const [modalCountry, setModalCountry] = useState('');
  const [modalTypeDocument, setModalTypeDocument] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [documentFileName, setDocumentFileName] = useState('');
  const [rutUploaded, setRutUploaded] = useState(false);
  const [rutFileName, setRutFileName] = useState('');
  const [bankUploaded, setBankUploaded] = useState(false);
  const [bankFileName, setBankFileName] = useState('');
  const [commerceUploaded, setCommerceUploaded] = useState(false);
  const [commerceFileName, setCommerceFileName] = useState('');
  const [logoUploaded, setLogoUploaded] = useState(false);
  const [logoFileName, setLogoFileName] = useState('');

  const [modalDocument, setModalDocument] = useState("");
  const [modalPhone, setModalPhone] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalExpirationDate, setModalExpirationDate] = useState(0);

  const [isFormValid, setIsFormValid] = useState(false);

  const progress_c = "En revision";

  const handleLogout = () => {
    // elimina el token y otra información del usuario almacenada en localStorage
    localStorage.clear();
    //REDIRIGIR AL LOGIN
    window.location.href = "/";
    // recarga la página para actualizar la interfaz de usuario
    // window.location.reload();
  };

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener("resize", handleDisabled);
    handleDisabled();
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  // Obtener todas las categorías internas desde el backend
  const fetchInternalCategoriesAll = useCallback(async () => {
    try {
      const { success, data, errors } = await InternalCategorieList();
      if (success) {
        setCategories(data.internalCategories);
      } else {
        // alert("No hay datos");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      alert("Error al obtener las categorías");
    }
  }, []);

  useEffect(() => {
    if (!!dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchInternalCategoriesAll();
  }, [fetchInternalCategoriesAll]);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const fetchProvider = async () => {
      const response = await getProvider(id);
      if (response.success) {
        const providerData = response.data.provider;
        setProvider(providerData);
        setName(providerData.name);
        setLegalName(providerData.legalName);
        setPhone(providerData.phone);
        setEmail(providerData.email);
        setStatus(providerData.status);
        setDocument(providerData.document);
        setTypeDocument(providerData.typeDocument);
        setCountry(providerData.country);
        setProgress(providerData.progress);
        setListProgress(providerData.progress);
        setLogo(providerData.logo?.url);
        setAttachmentUrl(providerData.attachments?.url);
        setDocumentRutUrl(providerData.documentRut?.url);
        setDocumentBankUrl(providerData.documentBank?.url);
        setExpirationDate(providerData.expired);

        setModalName(providerData.name);
        setModalPhone(providerData.phone);
        setModalDocument(providerData.document);
        setModalCountry(providerData.country);
        setModalTypeDocument(providerData.typeDocument);
        setModalExpirationDate(providerData.expirationDate);
      } else {
        setErrors({ fetchError: response.errors });
      }
    };

    fetchProvider();
  }, [id]);

  // Función para verificar si el formulario es válido
  const validateForm = () => {
    // Verifica si todos los campos obligatorios están completos y ambos checkboxes están marcados
    const isFieldsCompleted = (
      modalName.trim() !== "" &&
      modalCountry !== "" &&
      selectedCategory !== "" &&
      modalTypeDocument !== "" &&
      modalDocument.trim() !== "" &&
      modalPhone.trim() !== "" &&
      documentUploaded &&
      rutUploaded &&
      bankUploaded &&
      logoUploaded &&
      confirmCheckbox &&
      confirmCheckbox2
    );

    // Actualiza el estado de isFormValid con el resultado de la verificación
    setIsFormValid(isFieldsCompleted);
  };

  // Llama a validateForm cada vez que cambie el estado de los campos del formulario o los checkboxes
  useEffect(() => {
    validateForm();
  }, [modalName, modalCountry, selectedCategory, modalTypeDocument, modalDocument, modalPhone, documentUploaded, rutUploaded, bankUploaded, logoUploaded, confirmCheckbox, confirmCheckbox2]);

  const handleCheckboxChange = (event) => {
    setConfirmCheckbox(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setConfirmCheckbox2(event.target.checked);
  };

  const handleDocumentUpload = (event) => {
    setDocumentUploaded(event.target.files[0]);
    const file = event.target.files[0];
    setDocumentFileName(file.name);
  };

  const handleRutUpload = (event) => {
    setRutUploaded(event.target.files[0]);
    const file = event.target.files[0];
    setRutFileName(file.name);
  };

  const handleBankUpload = (event) => {
    setBankUploaded(event.target.files[0]);
    const file = event.target.files[0];
    setBankFileName(file.name);
  };

  const handleCommerceUpload = (event) => {
    setCommerceUploaded(event.target.files[0]);
    const file = event.target.files[0];
    setCommerceFileName(file.name);
  };

  const handleLogoUploaded = (event) => {
    setLogoUploaded(event.target.files[0]);
    const file = event.target.files[0];
    setLogoFileName(file.name);
  };

  const handleSave = async () => {
    const internalCategoryId = parseInt(selectedCategory);
    console.log("HOLA MUNDO", internalCategoryId)
    const resultUpdate = await updateProvider_2(id, {
      name: modalName,
      country: modalCountry,
      internalCategoryId: internalCategoryId,
      document: modalDocument,
      phone: modalPhone,
      typeDocument: modalTypeDocument,
      documentUploaded: documentUploaded,
      rutUploaded: rutUploaded,
      bankUploaded: bankUploaded,
      commerceUploaded: commerceUploaded,
      logo: logoUploaded,
      progress: progress_c,
      expirationDate: modalExpirationDate,
    });
    if (resultUpdate.success) {
      alert("Actualizado correctamente");
      fetchInternalCategoriesAll();
      handleLogout();
    } else {
      alert("No se pudo actualizar correctamente el proveedor");
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={logo} alt="profile-image" size="md" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {/* {providerEmail} */}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              Correo:
            </MDTypography>
            <MDTypography variant="body1">
              {email || "No disponible"}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              Teléfono:
            </MDTypography>
            <MDTypography variant="body1">
              {phone || "No disponible"}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              País:
            </MDTypography>
            <MDTypography variant="body1">
              {country || "No disponible"}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={12}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              Tipo de documento:
            </MDTypography>
            <MDTypography variant="body1">
              {typeDocument || "No disponible"}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={12}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              Documento:
            </MDTypography>
            <MDTypography variant="body1">
              {document || "No disponible"}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={12}>
            <MDTypography variant="subtitle1" fontWeight="bold">
              Vigencia de bonos:
            </MDTypography>
            <MDTypography variant="body1">
              {expirationDate || "No disponible"}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        {role !== 'admin' && progress_status === 'aprobado' && (
          <MDBox display="flex" justifyContent="center">
            <MDBox mt={2} textAlign="center">
              <MDButton variant="contained" color="warning" onClick={handleOpenModal}>
                Solicitar Actualización
              </MDButton>
            </MDBox>
          </MDBox>
        )}

        <MDBox display="flex" justifyContent="center">
          <MDBox mt={1.5}>
            <LogoutButton />
          </MDBox>
        </MDBox>
      </MDBox>

      {/* Modal */}

      {/* cuando se haga una solicitud le debera llegar la informacion de manera oportuna a AUX */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <MDBox sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 7.5 }}>
          <Grid container spacing={2}>
            {/* Campos con xs={5} */}
            <Grid item xs={5}>
              <MDTypography variant="h6">Ingresa el nombre de su marca:</MDTypography>
              <MDInput
                value={modalName}
                onChange={(event) => setModalName(event.target.value)}
                fullWidth
                required
              />

              <FormControl fullWidth>
                <MDTypography variant="h6">País</MDTypography>
                <Select
                  value={modalCountry}
                  onChange={(event) => setModalCountry(event.target.value)}
                  required
                >
                  <MenuItem value="" disabled>
                    Selecciona un pais
                  </MenuItem>
                  <MenuItem value="Argentina">Argentina</MenuItem>
                  <MenuItem value="Bolivia">Bolivia</MenuItem>
                  <MenuItem value="Brasil">Brasil</MenuItem>
                  <MenuItem value="Chile">Chile</MenuItem>
                  <MenuItem value="Colombia">Colombia</MenuItem>
                  <MenuItem value="Ecuador">Ecuador</MenuItem>
                  <MenuItem value="México">México</MenuItem>
                  <MenuItem value="Paraguay">Paraguay</MenuItem>
                  <MenuItem value="Perú">Perú</MenuItem>
                  <MenuItem value="Venezuela">Venezuela</MenuItem>
                  {/* Agrega más opciones de países según sea necesario */}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <MDTypography variant="h6">Categoría</MDTypography>
                <Select
                  value={selectedCategory}
                  onChange={(event) => setSelectedCategory(event.target.value)}
                  required
                >
                  <MenuItem value="">Selecciona una categoría</MenuItem>
                  {categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <MDTypography variant="h6">Tipo de documento</MDTypography>
                <Select
                  value={modalTypeDocument}
                  onChange={(event) => setModalTypeDocument(event.target.value)}
                  required
                >
                  <MenuItem value="">Selecciona un tipo de documento</MenuItem>
                  <MenuItem value="CC">CC</MenuItem>
                  <MenuItem value="NIT">NIT</MenuItem>
                  {/* Agrega más opciones de tipos de documento según sea necesario */}
                </Select>
              </FormControl>

              <MDTypography variant="h6">Número de documento</MDTypography>
              <MDInput
                value={modalDocument}
                onChange={(event) => setModalDocument(event.target.value)}
                fullWidth
                required
              />

              <MDTypography variant="h6">Número de teléfono</MDTypography>
              <MDInput
                value={modalPhone}
                onChange={(event) => setModalPhone(event.target.value)}
                fullWidth
                required
              />

              <FormControl fullWidth>
                <MDTypography variant="h6">Tiempo de vigencia</MDTypography>
                <Select
                  value={modalExpirationDate}
                  onChange={(event) => setModalExpirationDate(event.target.value)}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="3">3 Meses</MenuItem>
                  <MenuItem value="6">6 Meses</MenuItem>
                  <MenuItem value="12">12 Meses</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Campos con xs={7} */}
            <Grid item xs={7}>
              <MDTypography variant="h6">Cargar documento:</MDTypography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDButton
                  variant="contained"
                  color="warning"
                  component="label"
                  startIcon={<ControlPointIcon />}
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleDocumentUpload}
                  />
                </MDButton>
                {documentUploaded && (
                  <div style={{ marginLeft: 5, maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <MDTypography variant="body2" fontWeight="bold">
                      {documentFileName}
                    </MDTypography>
                  </div>

                )}
              </div>

              <MDTypography variant="h6">Cargar RUT:</MDTypography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDButton
                  variant="contained"
                  color="warning"
                  component="label"
                  startIcon={<ControlPointIcon />}
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleRutUpload}
                  />
                </MDButton>
                {rutUploaded && (
                  <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                    {rutFileName}
                  </MDTypography>
                )}
              </div>

              <MDTypography variant="h6">Cargar certificado bancario:</MDTypography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDButton
                  variant="contained"
                  color="warning"
                  component="label"
                  startIcon={<ControlPointIcon />}
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleBankUpload}
                  />
                </MDButton>
                {bankUploaded && (
                  <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                    {bankFileName}
                  </MDTypography>
                )}
              </div>

              <MDTypography variant="h6">Cargar Camara y Comercio:</MDTypography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDButton
                  variant="contained"
                  color="warning"
                  component="label"
                  startIcon={<ControlPointIcon />}
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleCommerceUpload}
                  />
                </MDButton>
                {commerceUploaded && (
                  <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                    {commerceFileName}
                  </MDTypography>
                )}
              </div>

              <MDTypography variant="h6">Logo de la marca:</MDTypography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDButton
                  variant="contained"
                  color="warning"
                  component="label"
                  startIcon={<ControlPointIcon />}
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleLogoUploaded}
                  />
                </MDButton>
                {logoUploaded && (
                  <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                    {logoFileName}
                  </MDTypography>
                )}
              </div>
              {/* Otros campos del formulario */}
            </Grid>

            <Grid item xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={confirmCheckbox}
                onChange={handleCheckboxChange}
                disabled={success}
              />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                {"Confirmo mi solicitud"}
              </Typography>
            </Grid>

            <Grid item xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={confirmCheckbox2}
                onChange={handleCheckboxChange2}
                disabled={success}
              />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                {"Acepto/Entiendo que el tiempo de respuesta es de 5 días habiles"}
              </Typography>
            </Grid>

            {/* Botón de guardar */}
            <Grid item xs={12}  >
              <MDButton variant="contained" color="primary" onClick={handleSave} disabled={!isFormValid}>
                Guardar cambios
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

    </ConfiguratorRoot>
  );
}

export default Configurator;

