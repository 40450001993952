import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// import TextField from '@mui/material/TextField';

import ControlPointIcon from '@mui/icons-material/ControlPoint';


import MDBox from 'components/MDBox';

import { InternalCategorieList } from "../../controllers/internal_category";
import { updateProvider, getProvider } from "controllers/providers";
import { CURRENT_PROVIDER } from "controllers/providers/constants"

import MDInput from 'components/MDInput';

const ProviderDetail = () => {

    const [documentUploaded, setDocumentUploaded] = useState(false);
    const [documentFileName, setDocumentFileName] = useState('');
    const [rutUploaded, setRutUploaded] = useState(false);
    const [rutFileName, setRutFileName] = useState('');
    const [bankUploaded, setBankUploaded] = useState(false);
    const [bankFileName, setBankFileName] = useState('');
    const [commerceUploaded, setCommerceUploaded] = useState(false);
    const [commerceFileName, setCommerceFileName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [logoUploaded, setLogoUploaded] = useState(false);

    const [confirmCheckbox, setConfirmCheckbox] = useState(false);
    const [confirmCheckbox2, setConfirmCheckbox2] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const dataFetchedRef = useRef(false);

    // const [phone, setPhone] = useState(null);
    const [userName, setUserName] = useState(null);
    const [document, setDocument] = useState(null);
    const [typeDocument, setTypeDocument] = useState("");
    const [country, setCountry] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [logo, setLogo] = useState(false);
    const [expirationDate, setExpirationDate] = useState(0);
    const progress = "En revision";
    const id = CURRENT_PROVIDER;

    const [providers, setProviders] = useState([]);

    const documentFileUpload = (event) => {
        setDocumentUploaded(event.target.files[0]);
        const file = event.target.files[0];
        setDocumentFileName(file.name);
        // Do something with the file
    };

    const rutFileUpload = (event) => {
        setRutUploaded(event.target.files[0]);
        const file = event.target.files[0];
        setRutFileName(file.name);
        // Do something with the file
    };

    const bankFileUpload = (event) => {
        setBankUploaded(event.target.files[0]);
        const file = event.target.files[0];
        setBankFileName(file.name);
        // Do something with the file
    };

    const commerceFileUpload = (event) => {
        setCommerceUploaded(event.target.files[0]);
        const file = event.target.files[0];
        setCommerceFileName(file.name);
        // Do something with the file
    };

    const logoFileUpload = (event) => {
        setLogoUploaded(event.target.files[0]);
        const file = event.target.files[0];
        setLogo(file.name);
        // Do something with the file
    };

    const handleCheckboxChange = (event) => {
        setConfirmCheckbox(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setConfirmCheckbox2(event.target.checked);
    };

    useEffect(() => {
        if (!!country && !!document && !!typeDocument && !!expirationDate 
            && !!selectedCategory && !!userName && !!rutUploaded && !!bankUploaded 
            && !!documentUploaded && !!commerceUploaded && !!logoUploaded) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [country, document, typeDocument, expirationDate, selectedCategory, userName,
        documentUploaded, rutUploaded, bankUploaded, commerceUploaded, logoUploaded]);

    //traida de categorias

    // Obtener todas las categorías internas desde el backend
    const fetchInternalCategoriesAll = useCallback(async () => {
        try {
            const { success, data, errors } = await InternalCategorieList();
            if (success) {
                setCategories(data.internalCategories);
            } else {
                // alert("No hay datos");
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
            alert("Error al obtener las categorías");
        }
    }, []);

    const fetchProvider = async () => {
        const { success, data, errors } = await getProvider(CURRENT_PROVIDER);
        if (success) {
            setProviders([data.provider]);
        } else {

        }
    };


    // Obtener las categorías internas al cargar el componente
    useEffect(() => {
        if (!!dataFetchedRef.current) return;
        dataFetchedRef.current = true;


        fetchProvider();
        fetchInternalCategoriesAll();
    }, [fetchInternalCategoriesAll, fetchProvider]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const buttonStatus = useMemo(() => {
        if (!!country && !!document && !!typeDocument && !!expirationDate 
            && !!selectedCategory && !!userName && !!rutUploaded && !!bankUploaded 
            && !!documentUploaded && !!commerceUploaded && !!logoUploaded && !!confirmCheckbox ) {
            return false;
        }
        return true;
    }, [country, document, typeDocument, expirationDate, selectedCategory, userName]);

    const handleSave = async () => {
        const category_id = parseInt(selectedCategory);
        const resultUpdate = await updateProvider(id,
            {
                country: country,
                internalCategoryId: category_id,
                document: document,
                typeDocument: typeDocument,
                documentUploaded: documentUploaded,
                rutUploaded: rutUploaded,
                bankUploaded: bankUploaded,
                commerceUploaded:commerceUploaded,
                logo: logoUploaded,
                userName: userName,
                progress: progress,
                expirationDate: expirationDate,
                //poner categoria
            });
        if (resultUpdate.success) {
            alert("Actualizado correctamente");
            fetchInternalCategoriesAll();
            window.location.href = "/dashboard"
        } else {
            alert("No se pudo actualizar correctamente el proveedor");
        }
    }

    console.log("HOLAAAAAAAAAAAA", isButtonDisabled);
    if (providers[0]) {
        const status = providers[0].progress.toString();
        if (status === "solicitado") {
            return (
                <DashboardLayout>
                    <DashboardNavbar />
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={1}>
                            {/* Campos con xs={5} */}
                            <Grid item xs={5}>
                                <MDTypography variant="h6">Ingresa el nombre de tu marca/empresa (es el que se mostrará en tus bonos):</MDTypography>
                                <MDInput
                                    value={userName}
                                    onChange={(event) => setUserName(event.target.value)}
                                    fullWidth
                                    required
                                />

                                <div style={{ marginTop: '1rem' }}>
                                    <FormControl fullWidth>
                                        <MDTypography variant="h6">País</MDTypography>
                                        <Select
                                            value={country}
                                            onChange={(event) => setCountry(event.target.value)}
                                            required
                                        >
                                            <MenuItem value="" disabled>
                                                Selecciona un pais
                                            </MenuItem>
                                            <MenuItem value="Argentina">Argentina</MenuItem>
                                            <MenuItem value="Bolivia">Bolivia</MenuItem>
                                            <MenuItem value="Brasil">Brasil</MenuItem>
                                            <MenuItem value="Chile">Chile</MenuItem>
                                            <MenuItem value="Colombia">Colombia</MenuItem>
                                            <MenuItem value="Ecuador">Ecuador</MenuItem>
                                            <MenuItem value="México">México</MenuItem>
                                            <MenuItem value="Paraguay">Paraguay</MenuItem>
                                            <MenuItem value="Perú">Perú</MenuItem>
                                            <MenuItem value="Venezuela">Venezuela</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ marginTop: '1rem' }}>
                                    <FormControl fullWidth>
                                        <MDTypography variant="h6">Categoría</MDTypography>
                                        <Select
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            required
                                        >
                                            <MenuItem value="">Selecciona una categoría</MenuItem>
                                            {categories.map(category => (
                                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ marginTop: '1rem' }}>
                                    <FormControl fullWidth>
                                        <MDTypography variant="h6">Tipo de documento</MDTypography>
                                        <Select
                                            value={typeDocument}
                                            onChange={(event) => setTypeDocument(event.target.value)}
                                            required
                                        >
                                            <MenuItem value="">Selecciona un tipo de documento</MenuItem>
                                            <MenuItem value="CC">CC</MenuItem>
                                            <MenuItem value="NIT">NIT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ marginTop: '1rem' }}>
                                    <MDTypography variant="h6">Número de documento</MDTypography>
                                    <MDInput
                                        value={document}
                                        onChange={(event) => setDocument(event.target.value)}
                                        fullWidth
                                        required
                                    />
                                </div>

                                <div style={{ marginTop: '1rem' }}>
                                    <FormControl fullWidth>
                                        <MDTypography variant="h6">Tiempo de vigencia</MDTypography>
                                        <Select
                                            value={expirationDate}
                                            onChange={(event) => setExpirationDate(event.target.value)}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="3">3 Meses</MenuItem>
                                            <MenuItem value="6">6 Meses</MenuItem>
                                            <MenuItem value="12">12 Meses</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>


                            <Grid item xs={2}>

                            </Grid>
                            {/* Campos con xs={5} */}
                            <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-around' }}>
                                <MDTypography variant="h6">Cargar documento:</MDTypography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDButton
                                        component="label"
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ControlPointIcon />}
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={documentFileUpload}
                                        />
                                    </MDButton>
                                    {documentUploaded && (
                                        <div style={{ marginLeft: 5, maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <MDTypography variant="body2" fontWeight="bold">
                                                {documentFileName}
                                            </MDTypography>
                                        </div>
                                    )}
                                </div>

                                <MDTypography variant="h6">Cargar RUT:</MDTypography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDButton
                                        component="label"
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ControlPointIcon />}
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={rutFileUpload}
                                        />
                                    </MDButton>
                                    {rutUploaded && (
                                        <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                                            {rutFileName}
                                        </MDTypography>
                                    )}
                                </div>

                                <MDTypography variant="h6">Cargar certificado bancario:</MDTypography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDButton
                                        component="label"
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ControlPointIcon />}
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={bankFileUpload}
                                        />
                                    </MDButton>
                                    {bankUploaded && (
                                        <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                                            {bankFileName}
                                        </MDTypography>
                                    )}
                                </div>

                                <MDTypography variant="h6">Cargar Camara y Comercio:</MDTypography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDButton
                                        component="label"
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ControlPointIcon />}
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={commerceFileUpload}
                                        />
                                    </MDButton>
                                    {commerceUploaded && (
                                        <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                                            {commerceFileName}
                                        </MDTypography>
                                    )}
                                </div>

                                <MDTypography variant="h6">Logo de la marca:</MDTypography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDButton
                                        component="label"
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ControlPointIcon />}
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={logoFileUpload}
                                        />
                                    </MDButton>
                                    {logoUploaded && (
                                        <MDTypography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                                            {logo}
                                        </MDTypography>
                                    )}
                                </div>
                            </Grid>

                            {/* <Grid item xs={12}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}
                            >
                                <Checkbox
                                    checked={confirmCheckbox}
                                    onChange={handleCheckboxChange}
                                    disabled={success}
                                />
                                <Typography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    sx={{ cursor: "pointer", userSelect: "none" }}
                                >
                                    {"Confirmo mi solicitud"}
                                </Typography>
                            </Grid> */}

                            {/* Botón de guardar */}
                            <br></br>
                            <Grid item xs={12}>
                                <MDButton variant="contained" color="primary" onClick={handleSave} disabled={buttonStatus && isButtonDisabled}>
                                    Guardar cambios
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </DashboardLayout>
            );
        } else if (status === "En revision") {
            return (
                <DashboardLayout>
                    <MDBox>
                        Te estan revisando en algun momento alguna de nuestras personas de contaduria dara respuesta a tu solicitud
                    </MDBox>
                </DashboardLayout>

            )
        }
    } else {
        return (
            <MDBox>
                loading
            </MDBox>
        )
    }
};

export default ProviderDetail;
