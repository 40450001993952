import HttpRequest from '../../services/http_request';
import lscache from 'lscache';
const current_provider = atob(lscache.get('current_provider'));

{/* Función para listar bonos canjeados por usuarios */}

export const BonusList = async (credentials) => {
    try {
        const resp = await HttpRequest({
        method: 'GET',
        url: '/api/v4/providers/bonus?provider_id='+current_provider,
        });

        return {
        success: true,
        data: resp.data
        }

        
    } catch (error) {
        return {
        success: false,
        errors: 'Ingrese un correo y contraseña validos por favor.'
        }
    }
};

export const BonusWallet = async (credentials) => {
    try {
        const resp = await HttpRequest({
        method: 'GET',
        url: '/api/v4/providers/bonus/report/'+current_provider,
        
        });
        // console.log("HOLAAAAAA: " + JSON.stringify(resp.data, null, 2));
        return {
        success: true,
        data: resp.data
        }

        
    } catch (error) {
        return {
        success: false,
        errors: 'Datos no encontrados'
        }
    }
};


{/* Función para actualizar el estado de un bono */}

export const BonuCompleted = async (id) => {
    try {
      const resp = await HttpRequest({
        method: 'POST',
        url: '/api/v4/providers/bonus/'+id+'/completed',
      });
          
      return {
        success: true,
        data: resp.data
      }
    } catch (error) {
      return {
        success: false,
        errors: 'Ingrese un correo y contraseña validos por favor.'
      }
    }
  };