import React, { useState } from "react";  
import { Link, useNavigate } from "react-router-dom";
import { createApplicant } from "controllers/providers";
import MessageModal from "components/Notification";
import kubera3 from '../../../assets/images/kubera_login_3.png';

function Cover() {
  const [name, setName] = useState(null);
  const [legalName, setLegalName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleChangeLegalName = (event) => {
    setLegalName(event.target.value);
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const { success, errors } = await createApplicant({
        email: email,
        name: name,
        legalName: legalName,
        phone: phone,
        password: password
      });
      if (success) {
        setShowSuccessModal(true);
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error en createApplicant:", error);
    }
  }

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className="body_register">
      <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
        <div className="container">
          <div className="card login-card">
            <div className="row no-gutters">
              <div className="col-md-7">
                <div className="card-body">
                  <p className="login-card-description">Registro Aliados</p>
                  <form onSubmit={handleSave}>
                    <div className="form-group">
                      <p>Nombre</p>
                      <input type="text" name="name" className="form-control" placeholder="Nombre" onChange={handleChangeName} required/>
                    </div>
                    <div className="form-group">
                      <p>Razón Social</p>
                      <input type="text" name="legalName" className="form-control" placeholder="Razón Social" onChange={handleChangeLegalName} required/>
                    </div>
                    <div className="form-group">
                      <p>Número celular</p>
                      <input type="text" name="phone" className="form-control" placeholder="30034568743" onChange={handleChangePhone} required/>
                    </div>
                    <div className="form-group">
                      <p>Correo</p>
                      <input type="email" name="email" className="form-control" placeholder="Correo" onChange={handleChangeEmail} required/>
                    </div>
                    <div className="form-group">
                      <p>Contraseña</p>
                      <input type="password" name="password" className="form-control" placeholder="Contraseña" onChange={handleChangePassword} required/>
                    </div>
                    <div className="form-group mb-4 d-flex justify-content-between">
                      <input name="register" className="btn btn-block login-btn mb-4" type="submit" value="Registrarse" required/>
                    </div>
                  </form>
                  <p className="login-card-footer-text" id="register-footer">¿Ya eres un aliado Kubera?
                    <Link to="/" className="link_register">Inicia sesion aquí</Link>
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <img src={kubera3} alt="login" className="login-card-img" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <MessageModal isOpen={showSuccessModal} message="Guardado correctamente" onClose={closeSuccessModal} />
      <MessageModal isOpen={showErrorModal} message="Error al guardar" onClose={closeErrorModal} />
    </div>
  );
}

export default Cover;
