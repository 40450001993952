import { BonusList } from "controllers/bonus";
import { InternalProductList } from "controllers/internal_products";
import React, { useEffect } from "react";

export default function DataCash() {
    const [bonus, setBonus] = React.useState([])
    const [productList, setProductList] = React.useState([]);
  
    const fetchBonusAll = async() => {
      const { success, data, errors } = await BonusList();
      if (success) {
  
        setBonus(data.bonus);
      } else {
        // alert('No hay data');
      }
    }
  
    const fetchInternalProductList = async () => {
      const { success, data, errors } = await InternalProductList();
  
      if (success) {
        setProductList(data.internalProducts);
      } else {
        // alert('No hay data');
      }
    };
  
    useEffect(() => {
      fetchInternalProductList();
      fetchBonusAll();
    }, []);
  
    let totalSum = 0;
    
    bonus.forEach((bonusObj) => {
      const matchingProduct = productList.find(
        (productObj) => productObj.id === bonusObj.internalProductId
      );
      if (matchingProduct !== undefined) {
        totalSum += parseInt(matchingProduct.price);
      }
    });
  
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(totalSum); // Total sum of prices of all bonuses formatted as Colombian currency
  
  }