import HttpRequest from '../../services/http_request';
import lscache from 'lscache';

const current_provider = atob(lscache.get('current_provider'));
const brand_id = lscache.get('brand_id');

{/* FUNCIONES PARA EL ROL DE PROVEEDOR */}

{/* Función para listar productos existentes de un proveedor*/}

export const InternalProductList = async (credentials) => {
  try {
    const resp = await HttpRequest({
      method: 'GET',
      url: '/api/v4/providers/internal_products?provider_id='+current_provider,
      // Agrega headers y otros parámetros de solicitud si es necesario
    });

    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: error
    };
  }
};

{/* Función para listar todos los productos existentes */}

export const InternalProductListAll = async () => {
  try {
    const resp = await HttpRequest({
      method: 'GET',
      url: '/api/v4/providers/products',
      // Agrega headers y otros parámetros de solicitud si es necesario
    });

    return {
      success: true,
      data: resp.data
    };
  } catch (error) {
    return {
      success: false,
      errors: error
    };
  }
};

{/* Función para crear productos bajo el rol de proveedor*/}

export const createProduct = async(fields) => {
  try {

    const formData = new FormData();
    formData.append('internal_product[name]', fields.name);
    formData.append('internal_product[price]', fields.price);
    formData.append('internal_product[is_active]', fields.state);
    formData.append('internal_product[internal_catalog_id]', brand_id);
    formData.append('internal_product[is_fixed]', fields.isFixed);
    formData.append('internal_product[stock]', fields.stock);
    formData.append('internal_product[provider_id]', current_provider);
    formData.append('internal_product[image]', fields.image);

    const resp = await HttpRequest({
      method: 'POST',
      url: '/api/v4/providers/internal_products',
      data: formData
    });
        
    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: error
    }
  }
};

{/* Función para actualizar productos existentes bajo el rol del proveedor */}

export const updateProduct = async (id, fields, image) => {
  try {

    const formData = new FormData();
    formData.append('internal_product[name]', fields.name);
    formData.append('internal_product[price]', fields.price);
    formData.append('internal_product[is_active]', fields.state);
    formData.append('internal_product[internal_catalog_id]', brand_id);
    formData.append('internal_product[is_fixed]', fields.isFixed);
    formData.append('internal_product[stock]', fields.stock);
    formData.append('internal_product[provider_id]', current_provider);
    formData.append('internal_product[image]', fields.image);

    const resp = await HttpRequest({
      method: 'PUT',
      url: '/api/v4/providers/internal_products/'+id,
      data: formData
    });
        
    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      errors: error
    }
  }
};

{/* Función para eliminar productos existentes de un proveedor*/}

export const deleteProduct = async (id) => {
  try {
      const resp = await HttpRequest ({
          method: 'DELETE',
          url: '/api/v4/providers/internal_products/'+id,
          data: {
              internal_products: {
                  id: id
              }
          }
      })

      window.location.reload()
      
      return {
          success: true,
          data: resp.data
      }
  } catch (error) {
      alert('Error al eliminar el producto');
      return {
          success: false,
          errors: error
      }
  }
};

{/* FUNCIONES PARA EL ROL DE ADMINISTRADOR */}

// ...

export const approveProduct = async (id) => {
  try {
    const resp = await HttpRequest({
      method: 'POST',
      url: `/api/v4/providers/internal_products/${id}/approve`,
      data: { is_active: true },
    });

    return {
      success: true,
      data: resp.data,
    };
  } catch (error) {
    return {
      success: false,
      errors: error,
    };
  }
};
