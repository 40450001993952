import axios from 'axios';
import { AUTH_TOKEN } from '../controllers/providers/constants';

//import { history } from '../config/history';
import lscache from 'lscache';
import _snakecase from 'lodash.snakecase';
import createHumps from '../../node_modules/lodash-humps/lib/createHumps';
import humps from 'lodash-humps';
import { keys } from '../config/apiCredentials';

const snakes = createHumps(_snakecase);

const instance = axios.create({
  baseURL: keys.REACT_APP_API_BASE_URL,
  timeout: 30000,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps(data)
  ],
  transformRequest: [
    data => snakes(data),
    ...axios.defaults.transformRequest
  ]
});

instance.interceptors.request.use(
  async config => {
    const authToken = lscache.get(AUTH_TOKEN)
    // console.log("Token Base: ", authToken)

    if (!!authToken) {
      config.headers['Access-Token'] = authToken
      config.headers['Token-Type'] = `Bearer`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    //console.log(error)
    const {response: {status, config}} = error;
    if (status === 401 && config.headers.Authorization){
      lscache.set(AUTH_TOKEN, '');
      //history.go('/login')
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;