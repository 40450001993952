export const keys = {
    REACT_APP_API_BASE_URL: (function() {
        //return "http://lvh.me:3000/";
        if (window.location.origin === "http://www.kubera.ninja" || window.location.origin === "http://kubera.ninja") {
            return 'https://kubera.work/';
            // console.log("NO-LOCAL"*30);
        } else {
            // console.log("LOCAL"*30);
            return 'http://lvh.me:3000/';
        }
    })()
}
