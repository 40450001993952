import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'components/styles/app.scss';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { FormControlLabel, Radio, RadioGroup, SvgIcon } from '@mui/material';
import { ShoppingBagOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@mui/material";
import MessageModal from "components/Notification";

import { InternalProductList, createProduct, deleteProduct, updateProduct } from '../../controllers/internal_products';
// import { BonusList, BonuCompleted } from '../../controllers/bonus';
// import { InternalCatalogList } from 'controllers/internal_catalogs';
// import { InternalCategorieList } from 'controllers/internal_category';
import MDInput from 'components/MDInput';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Navigate } from 'react-router-dom';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function preventDefault(event) {
  event.preventDefault();
}

const Products = () => {
  const [openReposiveDialog, setOpenReposiveDialog] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [catalogList, setCataloglist] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [formattedPrice, setFormattedPrice] = useState('');

  const dataFetchedRef = useRef(false);

  const [id, setId] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [imagen, setImagen] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [internalCatalogId, setInternalCatalogId] = React.useState(null);
  const [isNew, setIsNew] = React.useState(false);
  const [stock, setStock] = React.useState(null);
  const [isFixed, setIsFixed] = React.useState(false);
  const [selectedCatalog, setSelectedCatalog] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [descripcion, setDescripcion] = React.useState('');
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [tipoBono, setTipoBono] = React.useState('');
  const [bonoProducto, setBonoProducto] = React.useState('');


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const rowHandler = (event, row) => {

    setId(row.id);
    setName(row.name);
    setImagen(row.image);
    setState(row.state);
    setInternalCatalogId(row.internalCatalogId);
    setPrice(row.price);
    setState(row.isActive ? 'Activo' : 'Inactiva')
    setOpenReposiveDialog(true);
    setIsNew(false);
    setSelectedCatalog(row.selectedCatalog);

    if (row.stock) {
      setStock(row.stock);
    } else {
      setStock(0);
    }
    if (row.isFixed) {
      setIsFixed(row.isFixed);
    } else {
      setIsFixed(false);
    }
  }

  const clearField = () => {
    setId(null);
    setName(null);
    setImagen(null);
    setState(null);
    setInternalCatalogId(null);
    setPrice(null);
    setState(null)
    setStock(null);
    setIsFixed(false);
    setSelectedCatalog(null);
  }

  const fetchInternalProuctAll = useCallback(async () => {
    try {
      const { success, data, errors } = await InternalProductList();
      if (success) {
        setProducts(data?.internalProducts || []);
      } else {
        // alert('No hay data');
      }
    } catch (error) {
      console.error('Error fetching internal products:', error);
      // Puedes manejar el error de alguna manera si es necesario
    }
  }, []);


  const filteredProducts = products.filter((product) => {
    return product.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleNewProduct = () => {
    clearField();
    setOpenReposiveDialog(true);
    setIsNew(true)
  }

  useEffect(() => {
    if (!!dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchInternalProuctAll();
  }, [fetchInternalProuctAll]);


  const handleSave = async () => {
    setIsLoading(true); // Set loading state to true

    // Validar que todos los campos obligatorios estén llenos
    if (!name || !descripcion || !price || !stock) {
      alert('Por favor completa todos los campos obligatorios.');
      setIsLoading(false); // Set loading state back to false
      return;
    }

    var result = { success: null, data: null, errors: null };
    if (isNew) {
      const resultCreate = await createProduct({ name, price, state, stock });
      result.success = resultCreate.success;
      result.data = resultCreate.data;
      result.errors = resultCreate.errors;
    } else {
      const resultUpdate = await updateProduct(id, { name, image: imagen, price, state, stock });
      result.success = resultUpdate.success;
      result.data = resultUpdate.data;
      result.errors = resultUpdate.errors;
    }

    if (result.success) {
      setShowSuccessModal(true);
      setOpenReposiveDialog(false);
      fetchInternalProuctAll();
    } else {
      alert("No Guardo Correctamente", result.success);
    }

    setIsLoading(false); // Set loading state back to false
  }


  const deleteHandler = (id) => {
    var result = { success: null, data: null, errors: null };

    const resultDelete = deleteProduct(id);
    result.success = resultDelete.success;
    result.data = resultDelete.data;
    result.errors = resultDelete.errors;
  }

  const handleImagen = (event) => {
    setImagen(event.target.files[0])
  }

  // Configuración de columnas y filas para la tabla de categorías
  const prepareTableData = (products) => {
    const rows = products.map(({ isActive, id, name, price, stock, createdAt }) => ({
      is_active: isActive ? "Activo" : "Inactivo",
      id,
      name,
      price,
      stock,
      createdAt,
      action: (
        <div>
          {/* Aquí puedes agregar los componentes o elementos de acción */}
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderStartStartRadius: '15px',
            borderBottomLeftRadius: '15px'
          }} onClick={(e) => rowHandler(e, { id, name, price, stock, selectedCatalog })}>Editar</button>
          <span style={{ color: 'black', padding: '10px 5px', background: '#EFEFEF' }}>•</span>
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderEndEndRadius: '15px',
            borderStartEndRadius: '15px'
          }} onClick={() => deleteHandler(id)}>Eliminar</button>
        </div>

      )
    }));

    return {
      columns: [
        { Header: "ESTADO", accessor: "is_active", width: "10%", align: "left" },
        { Header: "Id", accessor: "id", width: "10%", align: "left" },
        { Header: "Nombre", accessor: "name", width: "25%", align: "left" },
        { Header: "Precio $", accessor: "price", align: "center" },
        { Header: "Disponibilidad", accessor: "stock", align: "center" },
        { Header: "Fec. Actualizacion", accessor: "createdAt", align: "center" },
        { Header: "Acciones", accessor: "action", align: "center" }
      ],
      rows: rows,
    };
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    Navigate("/");
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const handlePriceChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, '');
    const numericValue = parseFloat(rawValue) / 100; // Convertir a valor numérico y dividir por 100
    setPrice(numericValue); // Guardar el valor numérico

    const formattedValue = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
    }).format(numericValue); // Formatear el valor numérico
    setFormattedPrice(formattedValue);
  };


  const { columns, rows } = React.useMemo(() => prepareTableData(products), [products]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} spacing={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                coloredShadow="warning"
                className="card_form"
              >
                <SvgIcon component={ShoppingBagOutlined} color="white" />
                <MDTypography className="button_form_cards_title" variant="p" color="white" pb={1}>
                  Productos
                </MDTypography>
                <MDTypography className="button_form_cards" component="button" onClick={handleNewProduct}>Registrar</MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  rowHandler={rowHandler}
                  deleteHandler={deleteHandler}
                  handleSearch={handleSearch}
                  searchTerm={searchTerm}
                //filteredData={filteredCategories}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      { /* Formulario registrar productos */}
      <Dialog open={openReposiveDialog} onClose={() => setOpenReposiveDialog(false)} id="HOLAMORROCOYA" maxWidth="md">
        <DialogTitle sx={{ background: "#1A202C", color: "white !important", width: "600px" }}>Agregar Productos</DialogTitle>
        <DialogContent
          sx={{
            height: "400px",
            width: "600px",
            background: "#1A202C",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Grid className="container_FORM">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="p" color="white" p={2}>
                  Nombre
                </MDTypography>
                <MDInput
                  required
                  id="Nombre"
                  sx={{
                    p: 1,
                    backgroundColor: 'white !important',
                    padding: '1px',
                    borderRadius: '6px',
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  value={name || ""}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="p" color="white" p={2}>
                  Descripcion
                </MDTypography>
                <MDInput
                  required
                  id="Descripcion"
                  multiline
                  rows={4}
                  sx={{
                    p: 1,
                    backgroundColor: 'white !important',
                    padding: '1px',
                    borderRadius: '6px',
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  value={descripcion || ""}
                  onChange={(e) => setDescripcion(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="p" color="white" p={2}>
                  Stock
                </MDTypography>
                <MDInput
                  required
                  id="Stock"
                  sx={{
                    p: 1,
                    backgroundColor: 'white !important',
                    padding: '1px',
                    borderRadius: '6px',
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  value={stock || ""}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      setStock(e.target.value);
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="p" color="white" p={2}>
                  Precio
                </MDTypography>
                <MDInput
                  required
                  id="Precio"
                  sx={{
                    p: 1,
                    backgroundColor: 'white !important',
                    borderRadius: '6px',
                    padding: '1px',
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  value={formattedPrice}
                  onChange={handlePriceChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ background: "#1A202C", width: "600px" }}>
          <MDButton onClick={() => setOpenReposiveDialog(false)} disabled={isLoading}>Cancelar</MDButton>
          <MDButton onClick={handleSave} color="warning" disabled={isLoading}>{isLoading ? 'Guardando...' : 'Guardar'}</MDButton>
        </DialogActions>
      </Dialog>

      <MessageModal isOpen={showSuccessModal} message="Guardado correctamente" onClose={closeSuccessModal} />
    </DashboardLayout>
  );
};

export default Products;