import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ShoppingBagOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Divider, TextField, SvgIcon, IconButton } from "@mui/material";

import { InternalProductList } from '../../controllers/internal_products';
import { BonusList, BonuCompleted } from '../../controllers/bonus';

import kubera_icon from '../../assets/images/kubera_login_icono.png';
import CloseIcon from '@mui/icons-material/Close';

const Bonus = () => {
  const [openResponsiveDialog, setOpenResponsiveDialog] = useState(false);
  const [bonus, setBonus] = useState([]);
  const [productList, setProductList] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBonus, setSelectedBonus] = useState(null);

  useEffect(() => {
    const fetchBonusAll = async () => {
      const { success, data, errors } = await BonusList();
      if (success) {
        setBonus(data.bonus);
      } else {
        // Handle errors if necessary
      }
    };

    fetchBonusAll();
  }, []);

  useEffect(() => {
    // Obtener la lista de productos internos desde la API
    const fetchInternalProductList = async () => {
      const { success, data, errors } = await InternalProductList();

      if (success) {
        setProductList(data.internalProducts);
      } else {
        // alert('No hay data');
      }
    };

    fetchInternalProductList();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchAndShowDetails = () => {
    const foundBonus = bonus.find((bonu) => bonu.uuid.toLowerCase() === searchTerm.toLowerCase());

    if (foundBonus) {
      setSelectedBonus(foundBonus);
      setOpenResponsiveDialog(true);
    } else {
      alert('Código no encontrado. Verifica el código e intenta nuevamente.');
    }
  };

  const handleCompleteStatus = async (id) => {
    const { success, data, errors } = await BonuCompleted(id);
    if (success) {
      const updatedBonusList = bonus.map((bonu) => bonu.id === id ? { ...bonu, completed: true } : bonu);
      setBonus(updatedBonusList);
      setOpenResponsiveDialog(false);
    } else {
      // Handle errors if necessary
    }
  };

  const handleCloseDialog = () => {
    setOpenResponsiveDialog(false);
    setSelectedBonus(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                coloredShadow="warning"
                className="card_form"
              >
                <SvgIcon component={ShoppingBagOutlined} color="white" />
                <MDTypography variant="h4" color="white">
                  Lector de Código
                </MDTypography>
              </MDBox>
              <MDBox pt={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DialogContent sx={{ width: "500px", textAlign: "center" }}>
                  <TextField
                    name="searchTerm"
                    label="Código Bono"
                    fullWidth
                    required
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <Divider />
                  <MDButton onClick={handleSearchAndShowDetails} color="warning" variant="contained">
                    Buscar
                  </MDButton>
                </DialogContent>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Diálogo para mostrar detalles del bono */}
      <Dialog open={openResponsiveDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ background: 'linear-gradient(to right, #ff9800, #ff5722)', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
          Detalles del Bono
          <IconButton onClick={handleCloseDialog} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: "500px", textAlign: "center" }}>
          {selectedBonus && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ padding: 20 }}>
                <img src={kubera_icon} style={{ width: '160px', height: '160px' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <br></br>
                <p><strong>Nombre:</strong> {productList.find((product) => product.id === selectedBonus.internalProductId)?.name || 'Vacío'}</p>
                <br></br>
                <p><strong>Uuid:</strong> {selectedBonus.uuid}</p>
                <br></br>
                <p><strong>Estado: </strong>
                  <span style={{ color: selectedBonus.status === 'generated' ? 'green' : 'red' }}>
                    {selectedBonus.status === 'generated' ? 'Generado' : 'Usado'}
                  </span>
                </p>
                <br></br>
                <p><strong>Fec. Actualización:</strong> {new Date(selectedBonus.createdAt).toLocaleDateString()}</p>
              </div>
            </div>
          )}
          <Divider />
          {selectedBonus && (
            <MDButton
              onClick={() => handleCompleteStatus(selectedBonus.id)}
              color="warning"
              variant="contained"
              disabled={selectedBonus.status !== 'generated'}
            >
              {selectedBonus.status === 'generated' ? 'Completar' : 'Bono Usado'}
            </MDButton>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout >
  );
};

export default Bonus;
