import React, { useEffect, useRef, useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { ShoppingBagOutlined } from '@mui/icons-material';
import { SvgIcon } from "@mui/material";

import { InternalProductList } from '../../controllers/internal_products';
import { BonusList } from '../../controllers/bonus';

function preventDefault(event) {
  event.preventDefault();
}

const Bonus = () => {
  const dataFetchedRef = useRef(false);
  const [bonus, setBonus] = React.useState([])
  const [productList, setProductList] = React.useState([]);

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const rowHandler = (event, row) => {
    setId(row.id);
    setUuid(row.uuid);
    setQry(process.env.REACT_APP_API_BASE_URL + "" + row.image.url);
    setOpenReposiveDialog(true);
  }

  const fetchBonusAll = async () => {
    const { success, data, errors } = await BonusList();
    if (success) {

      setBonus(data.bonus);
    } else {
      // alert('No hay data');
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchBonusAll();
  }, [fetchBonusAll]);

  useEffect(() => {
    // Obtener la lista de productos internos desde la API
    const fetchInternalProductList = async () => {
      const { success, data, errors } = await InternalProductList();

      if (success) {
        setProductList(data.internalProducts);
      } else {
        // alert('No hay data');
      }
    };

    fetchInternalProductList();
  }, []);

  // const handleCompleteStatus = async (id) => {
  //   const { success, data, errors } = await BonuCompleted(id);
  //   fetchBonusAll();
  //   setOpenReposiveDialog(false);
  // }

  // Configuración de columnas y filas para la tabla de categorías
  const prepareTableData = (bonus, productList) => {
    const rows = bonus.map(({ id, uuid, internalProductId, createdAt, status }) => ({
      id,
      uuid,
      internalProductId: productList.find((product) => product.id === internalProductId)?.name || 'Vacío',
      createdAt,
      status: status !== 'used' ? 'activo' : 'Completado',
      // backgroundColor: status !== 'used' ? 'green' : 'red', // Agregamos una propiedad para el color de fondo
    }));    

    return {
      columns: [
        { Header: "Id", accessor: "id", width: "10%", align: "left" },
        { Header: "Uuid", accessor: "uuid", width: "25%", align: "left" },
        { Header: "Nombre", accessor: "internalProductId", align: "center" },
        { Header: "Fec. Actualizacion", accessor: "createdAt", align: "center" },
        { 
          Header: "Estado",
          accessor: "status",
          align: "center",
          Cell: ({ value }) => (
            <div style={{ backgroundColor: value === 'activo' ? '#37be22' : '#fb6666', width: value === 'activo' ? '200%' : '120%', color: 'white', borderRadius: '15px' }}>
              {value}
            </div>
          )
        },
      ],
      rows: rows,
    };
  };

  const { columns, rows } = React.useMemo(() => prepareTableData(bonus, productList), [bonus, productList]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                coloredShadow="warning"
                className="card_form"
              >
                <SvgIcon component={ShoppingBagOutlined} color="white" />
                <MDTypography variant="h4" color="white">
                  Redenciones
                </MDTypography>
                {/* <MDButton onClick={handleNewCategorie}>Registrar</MDButton> */}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  rowHandler={rowHandler}
                  //   deleteHandler={deleteHandler}
                  handleSearch={handleSearch}
                  searchTerm={searchTerm}
                //   filteredData={filteredCategories}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Bonus;
