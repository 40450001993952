import React from 'react';
import lscache from 'lscache';
import { Navigate } from 'react-router-dom';
import { AUTH_TOKEN, AUTH_ROLE } from '../controllers/providers/constants';


const AuthRoute = ({ component: Component, ...rest }) => {
    const isLogged = lscache.get(AUTH_TOKEN);
    const role = atob(lscache.get(AUTH_ROLE));

    if (!!isLogged) {
        if (role == 'user') {
            return <Navigate to="/dashboard" />
        } else if(role == 'admin'){
            return <Navigate to="/admin_dashboard" />
        }
        
    } else {
        return <Component/> ;
    }   
}

export default AuthRoute;

