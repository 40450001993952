import HttpRequest from '../../services/http_request';

{/* Función para listar categorías existentes */}

export const InternalCategorieList = async (credentials) => {
    try {
        const resp = await HttpRequest({
            method: 'GET',
            url: '/api/v4/providers/internal_categories'
        });
        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        return {
            success: false,
            errors: 'Ingrese un correo y contraseña validos por favor.'
        }
    }
};

{/* Función para crear categorías */}

export const createCategorie = async (fields) => {
    try {
        const formData = new FormData();
        formData.append('internal_categorie[name]', fields.name);
        formData.append('internal_categorie[is_active]', fields.isActive);

        const resp = await HttpRequest({
            method: 'POST',
            url: '/api/v4/providers/internal_categories',
            data: formData
        });

        return {
            success: true,
            data: resp.data
        }   
    } catch (error) {
        return {
            success: false,
            errors: error
        }
    }
};

{/* Función para actualizar categorías */}

export const updateCategorie = async (id, fields) => {
    try {
        const formData = new FormData();
        formData.append('internal_categorie[name]', fields.name);
        formData.append('internal_categorie[is_active]', fields.isActive);

        const resp = await HttpRequest ({
            method: 'PUT',
            url: '/api/v4/providers/internal_categories/'+id,
            data: formData
        });

        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        return {
            success: false,
            errors: error
        }
    }
};

{/* Función para eliminar categorías */}

export const deleteCategorie = async (id) => {
    try {
        const resp = await HttpRequest ({
            method: 'DELETE',
            url: '/api/v4/providers/internal_categories/'+id,
            data: {
                internal_categories: {
                    id: id
                }
            }
        })

        window.location.reload()
        
        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        alert('Error al eliminar la categoría, cuentas con marcas asociadas a esta categoría');
        return {
            success: false,
            errors: error
        }
    }
};