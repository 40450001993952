import Dashboard from "layouts/dashboard";
import A_Dashboard from "layouts/admin_dashboard";
import Redeem from "layouts/history_bonus";
import SignUp from "layouts/authentication/sign-up";
import lscache from 'lscache';
import Provider from 'layouts/provider'
import { AUTH_ROLE } from 'controllers/providers/constants';
import React from 'react';

import Icon from "@mui/material/Icon";
// import AddBusinessIcon from '@mui/icons-material/AddBusiness';
// import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import CategoryIcon from '@mui/icons-material/Category';
import Category from "layouts/category";
import Productos from "layouts/products";
import Wallet from "layouts/wallet";
import Multicatalogo from "layouts/multicatalogo";
import ProviderDetail from "layouts/provider/providerDetail";
import RegisterContinue from "layouts/register";
import Scan from "layouts/scanner";
import { useParams } from "react-router-dom";

const role = atob(lscache.get(AUTH_ROLE));
const progress_status = atob(lscache.get('progress_status'));
const current_provider = atob(lscache.get('current_provider'));

// const { id } = useParams();

// Asumiendo que tienes una variable que almacena el valor de provider progress
const providerProgress = progress_status;
// console.log(current_provider);

const publicRoutes = [
  {
    type: "collapse",
    name: "SingUp",
    key: "singUp",
    icon: <Icon >dashboard</Icon>,
    route: "/register",
    component: <SignUp />,
  }
];

const userRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon >dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // Usando el operador ternario y el operador AND para renderizar condicionalmente la ruta
  (providerProgress === "solicitado" || providerProgress === "En revision") && {
    type: "collapse",
    name: "¡Termina tu registro!",
    key: "register_continue",
    icon: <LockOpenIcon />,
    route: "/register_continue/"+current_provider.toString(),
    component: <RegisterContinue />,
  },
  // Usando el operador OR para mostrar Productos y Bonos solo si providerProgress es diferente de solicitado o está vacío
  (providerProgress !== "solicitado" && providerProgress !== "En revision" || providerProgress === "" ) && {
    type: "collapse",
    name: "Productos",
    key: "products",
    icon: <Icon >inventory</Icon>,
    route: "/products",
    component: <Productos />,
  },
  (providerProgress !== "solicitado" && providerProgress !== "En revision" || providerProgress === "" ) && {
    type: "collapse",
    name: "Scanner",
    key: "scanner",
    icon: <Icon >crop_free</Icon>,
    route: "/scan",
    component: <Scan />,
  },
  // (providerProgress !== "solicitado" && providerProgress !== "En revision" || providerProgress === "" ) && {
  //   type: "collapse",
  //   name: "Redenciones",
  //   key: "redenciones",
  //   icon: <Icon >receipt_long</Icon>,
  //   route: "/redenciones",
  //   component: <Redeem />,
  // },
  (providerProgress !== "solicitado" && providerProgress !== "En revision" || providerProgress === "" ) && {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    icon: <Icon >wallet</Icon>,
    route: "/wallet",
    component: <Wallet />,
  }

];

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin_dashboard",
    component: <A_Dashboard />,
  },
  {
    type: "collapse",
    name: "Provider",
    key: "provider",
    icon: <Icon fontSize="small">add_business</Icon>,
    route: "/provider",
    component: <Provider />,
  },
  {
    type: "collapse",
    name: "Categorias",
    key: "category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/category",
    component: <Category />,
  },
  {
    type: "collapse",
    name: "Multicatalogo",
    key: "multicatalogo",
    icon: <Icon fontSize="small">ballot</Icon>,
    route: "/multicatalogo",
    component: <Multicatalogo />,
  },
  {
    key: "providerDetail",
    route: "/provider/:id",
    component: <ProviderDetail />,
  }
];

const routes = role === 'user' ? userRoutes : role === 'admin' ? adminRoutes : publicRoutes;

export default routes;
