
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BigCard from "examples/Cards/BigCard"

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import DataBonus from 'layouts/dashboard/data/dataBonus'
import DataCash from 'layouts/dashboard/data/dataCash'
import DataSells from 'layouts/dashboard/data/dataSells'
import React, { useEffect, useRef } from "react";
import Divider from "@mui/material/Divider";

import { BonusList } from "controllers/bonus";
import { InternalProductList } from "controllers/internal_products";

function ProviderLayout() {
  const dataFetchedRef = useRef({ bonusFetched: false, productsFetched: false });
  const [bonus, setBonus] = React.useState([])
  const [products, setProducts] = React.useState([]);
  const [redencionesSemanales, setRedencionesSemanales] = React.useState(Array(7).fill(0));

  const totalBonus = bonus.length;
  const usedBonus = bonus.filter(b => b.status === "used").length;
  const activeProducts = products.filter(p => p.status === "aprobado").length;

  const dataCash = DataCash();
  const dataBonus = DataBonus();
  const dataSells = DataSells();

  const today = new Date();
  const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
  const lastMonthName = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(new Date(today.getFullYear(), lastMonth));

  const fetchBonusData = async () => {
    try {
      const { success, data, errors } = await BonusList();
      if (success) {
        setBonus(data.bonus);
      } else {
        setBonus([]);
      }
    } catch (error) {
      console.error("Error fetching bonus data:", error);
      setBonus([]);
    }
  };

  const fetchProductData = async () => {
    try {
      const { success, data, errors } = await InternalProductList();
      if (success) {
        // console.log(data);
        setProducts(data?.internalProducts || []);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      setProducts([]);
    }
  };

  const procesarRedencionesSemanales = (bonos) => {
    const redencionesPorDia = Array(7).fill(0); // Inicializa con 0 para cada día de la semana
    bonos.forEach(bono => {
      try {
        const diaRedencion = new Date(bono.createdAt).getDay(); // 0 (domingo) a 6 (sábado)
        redencionesPorDia[diaRedencion]++;
      } catch (error) {
        console.error("Error procesando el bono:", bono, error);
      }
    });
    // console.log("Redenciones por día:", redencionesPorDia); // Log para verificar los datos procesados
    return redencionesPorDia;
  };
  

  useEffect(() => {
    if (!dataFetchedRef.current.bonusFetched) {
      dataFetchedRef.current.bonusFetched = true;
      fetchBonusData();
    }

    if (!dataFetchedRef.current.productsFetched) {
      dataFetchedRef.current.productsFetched = true;
      fetchProductData();
    }
  }, []);

  useEffect(() => {
    // console.log("Datos de bonus:", bonus); // Log para verificar los datos de bonus
    const redencionesPorDia = procesarRedencionesSemanales(bonus);
    setRedencionesSemanales(redencionesPorDia);
  }, [bonus]);
  
  const datosBarChart = {
    labels: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    datasets: { label: "Canjes", data: redencionesSemanales },
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <BigCard
                id="totalCard"
                color="success"
                icon="attach_money"
                title="Total"
                count={dataSells}
                percentage={{
                  color: "success",
                  label: "Dinero generado",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="report"
                title="Productos"
                count={activeProducts}
                percentage={{
                  color: "success",
                  amount: activeProducts + " -",
                  label: "productos activos",
                }}
                fontSize="50px"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="leaderboard"
                title="Bonos"
                count={totalBonus}
                percentage={{
                  color: "success",
                  amount: totalBonus + " -",
                  label: "Bonos generados",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="store"
                title="Redenciones"
                count={usedBonus}
                percentage={{
                  color: "success",
                  amount: usedBonus + " -",
                  label: "Bonos redimidos",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ display: "inline" }}>
          <MDBox >
            <Divider color="black" bgColor="black" />
          </MDBox>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3} >
                <ReportsBarChart
                  color="dark"
                  title="Canjes Por día de semana"
                  // description="Last Campaign Performance"
                  date={`actualizado el mes de ${lastMonthName}`}
                  chart={datosBarChart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3} className="grafic_card_grey">
                <ReportsLineChart
                  title="Bonos generados por mes"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  date={`actualizado el mes de ${lastMonthName}`}
                  chart={dataBonus}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Ventas mensuales"
                  // description="Last Campaign Performance"
                  date={`actualizado el mes de ${lastMonthName}`}
                  chart={dataCash}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}

    </DashboardLayout>
  );
}

export default ProviderLayout;
