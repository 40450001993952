import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import 'components/styles/app.scss';
import {
  InternalCategorieList,
  createCategorie,
  updateCategorie,
  deleteCategorie,
} from "../../controllers/internal_category";
//import categoryData from "layouts/category/data/categoryData";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import MDInput from "components/MDInput";

const Category = () => {
  const [openReposiveDialog, setOpenReposiveDialog] = useState(false);
  const [categories, setCategories] = useState([]);
  const dataFetchedRef = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isNew, setIsNew] = useState(false);

  const rowHandler = (event, row) => {
    setId(row.id);
    setName(row.name);
    setIsActive(row.isActive ? 'Activo' : 'Inactivo');
    setOpenReposiveDialog(true);
    setIsNew(false);
  }

  // Función para manejar la búsqueda de categorías
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredCategories = categories.filter((category) => {
    return category.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Limpiar los campos del formulario
  const clearFields = () => {
    setId(null);
    setName("");
    setIsActive("");
  };

  // Obtener todas las categorías internas desde el backend
  const fetchInternalCategoriesAll = useCallback(async () => {
    const { success, data, errors } = await InternalCategorieList();
    if (success) {
      setCategories(data.internalCategories);
    } else {
      // alert("No hay datos");
    }
  }, []);

  // Manejar el evento de crear una nueva categoría
  const handleNewCategorie = () => {
    clearFields();
    setOpenReposiveDialog(true);
    setIsNew(true);
  };

  // Obtener las categorías internas al cargar el componente
  useEffect(() => {
    if (!!dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchInternalCategoriesAll();
  }, [fetchInternalCategoriesAll]);

  // Guardar una categoría nueva o actualizar una existente
  const handleSave = async () => {
    if (isNew) {
      const resultCreate = await createCategorie({ name, isActive });
      if (resultCreate.success) {
        alert("Guardado correctamente");
        setOpenReposiveDialog(false);
        fetchInternalCategoriesAll();
      } else {
        alert("No se pudo guardar correctamente");
      }
    } else {
      const resultUpdate = await updateCategorie(id, { name, isActive });
      if (resultUpdate.success) {
        alert("Actualizado correctamente");
        setOpenReposiveDialog(false);
        fetchInternalCategoriesAll();
      } else {
        alert("No se pudo actualizar correctamente");
      }
    }
  };

  // Manejar la eliminación de una categoría
  const deleteHandler = async (id) => {
    const resultDelete = await deleteCategorie(id);
    if (resultDelete.success) {
      alert("Eliminado correctamente");
      fetchInternalCategoriesAll();
    } else {
      alert("No se pudo eliminar correctamente");
    }
  };

  // Configuración de columnas y filas para la tabla de categorías
  const prepareTableData = (categories) => {
    const rows = categories.map(({ id, name, isActive, updatedAt }) => ({
      id,
      name,
      isActive,
      updatedAt,
      action: (
        <div>
          {/* Aquí puedes agregar los componentes o elementos de acción */}
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderStartStartRadius: '15px',
            borderBottomLeftRadius: '15px'
          }} onClick={(e) => rowHandler(e, { id, name, isActive })}>Editar</button>
          <span style={{ color: 'black', padding: '10px 5px', background: '#EFEFEF' }}>•</span>
          <button style={{
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderEndEndRadius: '15px',
            borderStartEndRadius: '15px'
          }} onClick={() => deleteHandler(id)}>Eliminar</button>
        </div>
      )
    }));

    return {
      columns: [
        { Header: "Id", accessor: "id", width: "45%", align: "left" },
        { Header: "Nombre", accessor: "name", width: "45%", align: "left" },
        { Header: "Estado", accessor: d => d.isActive.toString() === 'true' ? 'Activo' : 'Inactivo', align: "center" },
        { Header: "Fec. Actualizacion", accessor: "updatedAt", align: "center" },
        { Header: "Acciones", accessor: "action", align: "center" },
      ],
      rows: rows,
    };
  };

  // Dentro de tu componente Category
  const { columns, rows } = useMemo(() => prepareTableData(categories), [categories]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                coloredShadow="warning"
                className="card_form"
              >
                <MDTypography className="button_form_cards_title" variant="p" color="white" pb={1}>
                  Categorias
                </MDTypography>
                <MDTypography className="button_form_cards" component="button" onClick={handleNewCategorie}>Registrar</MDTypography>

              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  rowHandler={rowHandler}
                  deleteHandler={deleteHandler}
                  handleSearch={handleSearch}
                  searchTerm={searchTerm}
                  filteredData={filteredCategories}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={openReposiveDialog} onClose={() => setOpenReposiveDialog(false)} >
        <DialogTitle style={{ background: "#1A202C", color: "white" }}>Agregar Categoría</DialogTitle>
        <DialogContent sx={{ width: "500px", height: "225px", background: "#1A202C" }}>
          <form onSubmit={handleSave}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDTypography className="" variant="p" color="white" p={2}>
                  Nombre de la categoría
                </MDTypography>
                <MDInput
                  required={true}
                  name="name"
                  id="Nombre de la categoría"
                  className="category_name_form"
                  sx={{ p: 1 }}
                  value={name || ""}
                  onChange={(e) => {
                    if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                      setName(e.target.value);
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography className="" variant="p" color="white" p={2}>
                  ¿Activo?
                </MDTypography>
                <Select
                  required={true}
                  id="demo-simple-select"
                  value={isActive}
                  label="¿Activo?"
                  onChange={(e) => setIsActive(e.target.value)}
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '4px', height: "40px !important" }}
                  fullWidth
                >
                  <MenuItem value={true}>Activo</MenuItem>
                  <MenuItem value={false}>Inactivo</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions style={{ background: "#1A202C" }}>
          <MDButton onClick={() => setOpenReposiveDialog(false)}>Cancelar</MDButton>
          <MDButton onClick={handleSave} color="warning">
            Guardar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Category;
