import { useState, useEffect } from "react";
import 'components/styles/app.scss';

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/k-kubera.png";
import brandDark from "assets/images/k-kubera.png";

import PrivateRoute from 'config/private_route.js';
import AuthRoute from "config/auth_route";

import Dashboard from "layouts/dashboard";
import Login from "layouts/authentication/sign-in";
import Provider from "layouts/provider"
import lscache from "lscache";

// Establecer el tiempo límite de inactividad en milisegundos (por ejemplo, 5 minutos)
// const TIMEOUT = 15 * 60 * 1000;

// Crear una variable para almacenar el ID del temporizador
// let timeoutID;

// Crear una función para reiniciar el temporizador
// const resetTimer = () => {
//     // Limpiar el temporizador anterior
//     clearTimeout(timeoutID);

//     // Establecer un nuevo temporizador
//     timeoutID = setTimeout(() => {
//         // Limpiar el localStorage cuando se alcance el tiempo límite
//         localStorage.clear();
//         window.location.replace('/login');
//     }, TIMEOUT);
// }

// Agregar un controlador de eventos para reiniciar el temporizador cuando el usuario interactúe con la página
// window.addEventListener('mousemove', resetTimer);
// window.addEventListener('mousedown', resetTimer);
// window.addEventListener('keypress', resetTimer);
// window.addEventListener('touchmove', resetTimer);

// Iniciar el temporizador al cargar la página
// if (localStorage.length > 0) {
//   resetTimer();
// }

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const authToken = lscache.get('PROVIDER_AUTH_TOKEN');
  const isLoggedIn = authToken !== null && authToken !== undefined;

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && isLoggedIn && (
        <>
          <div className="kubera-nav">
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </div>
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route exact path='/' element={<AuthRoute component={Login} />} />
        <Route exact path='/login' element={<AuthRoute component={Login} />} />
        {/* Normal User */}
        <Route exact path='/dashboard' element={<PrivateRoute component={Dashboard} allowedRoles={['user']} />} />

        <Route exact path='/provider' element={<PrivateRoute component={Provider} allowedRoles={['admin']} />} />

      </Routes>
    </ThemeProvider>
  );
}
