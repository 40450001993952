import HttpRequest from '../../services/http_request';

{/* Función para listar marcas existentes */}

export const InternalCatalogList = async (credentials) => {
    try {
        const resp = await HttpRequest({
            method: 'GET',
            url: '/api/v4/providers/internal_catalogs'
        });
        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        return {
            success: false,
            errors: 'Ingrese un correo y contraseña validos por favor.'
        }
    }
};

{/* Función para crear marcas */}

export const createCatalog = async (fields) => {
    try {
        const formData = new FormData();
        formData.append('internal_catalog[name]', fields.name);
        formData.append('internal_catalog[logo]', fields.logo);
        formData.append('internal_catalog[is_active]', fields.active);
        formData.append('internal_catalog[internal_category_id]', fields.categoryId);

        const resp = await HttpRequest({
            method: 'POST',
            url: '/api/v4/providers/internal_catalogs',
            data: formData
        });
        // console.log(resp.data);
        return {
            success: true,
            data: resp.data
        }   
    } catch (error) {
        return {
            success: false,
            errors: error
        }
    }
};

{/* Función para actualizar marcas existentes */}

export const updateCatalog = async (id, fields) => {
    try {
        const formData = new FormData();
        formData.append('internal_catalog[name]', fields.name);
        formData.append('internal_catalog[logo]', fields.logo);
        formData.append('internal_catalog[is_active]', fields.is_active);
        formData.append('internal_catalog[internal_category_id]', fields.selectedCategorie);

        const resp = await HttpRequest ({
            method: 'PUT',
            url: '/api/v4/providers/internal_catalogs/'+id,
            data: formData
        });
        // console.log(resp.data);
        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        return {
            success: false,
            errors: error
        }
    }
};

{/* Función para eliminar marcas existentes */}

export const deleteCatalog = async (id) => {
    try {
        const resp = await HttpRequest ({
            method: 'DELETE',
            url: '/api/v4/providers/internal_catalogs/'+id,
            data: {
                internal_catalogs: {
                    id: id
                }
            }
        })

        window.location.reload()
        
        return {
            success: true,
            data: resp.data
        }
    } catch (error) {
        return {
            success: false,
            errors: error
        }
    }
};