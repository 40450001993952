import { useState, useEffect } from 'react';
import { showProvider } from 'controllers/providers';
import lscache from 'lscache';

export const AUTH_TOKEN = 'PROVIDER_AUTH_TOKEN';
export const AUTH_ROLE = 'PROVIDER_AUTH_ROLE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const CURRENT_PROVIDER = atob(lscache.get('current_provider'));

export const useProgressStatus = (id) => {
  const [progress, setProgress] = useState('');

  useEffect(() => {
    const fetchProvider = async () => {
      const response = await showProvider(id);
      if (response.success) {
        const providerData = response.data.provider;
        setProgress(providerData.progress);
      } else {
        setProgress('');
      }
    };

    fetchProvider();
  }, [id]);

  return progress;
};

// Ejemplo de cómo utilizarlo en otro archivo
// const progress = useProgressStatus(CURRENT_PROVIDER);
// export const PROGRESS_STATUS = progress;
