import React from 'react';
import PropTypes from 'prop-types';

const MessageModal = ({ isOpen, message, onClose }) => {
    // Estilos para el contenido del modal
    const modalContentStyle = {
        backgroundColor: 'white',
        textAlign: 'center',
        borderRadius: '15px',
        border: 'none',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // Agrega una sombra con desplazamiento horizontal de 0px, desplazamiento vertical de 0px, difuminación de 10px y color rgba(0, 0, 0, 0.5)
    };
    

    // Estilos para el cuerpo del modal
    const modalBodyStyle = {
        padding: '20px',
    };
    // #F7931E
    // Estilos para el pie de página del modal
    const modalFooterStyle = {
        backgroundColor: '#344767',
        padding: '10px',
        textAlign: 'right',
        borderBottomLeftRadius: '15px', 
        borderBottomRightRadius: '15px', 
    };

    const modalButtonStyle = {
        backgroundColor: '#F7931E',
        borderRadius: '7.5px',
        fontWeight: 'bold',
        border: 'none',
        color: 'white',
    }

    const modalMessageStyle = {
        color: 'black',
        fontWeight: '600',
    }

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <p style={modalMessageStyle}>{message}</p>
                    </div>
                    <div className="modal-footer" style={modalFooterStyle}>
                        <button type="button" className="btn btn-warning" onClick={onClose} style={modalButtonStyle}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

MessageModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default MessageModal;
