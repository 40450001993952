/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */


import React, { useEffect, useCallback, useRef } from 'react';
import { BonusList } from "../../../../../controllers/bonus"


export default function Data() {

  const dataFetchedRef = useRef(false);
  const [bonus, setBonus] = React.useState([]);

  const fetchBonusAll = async () => {
    const { success, data, errors } = await BonusList();
    if (success) {
      setBonus(data.bonus);
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchBonusAll();
  }, [fetchBonusAll]);

  const rows = bonus ? bonus.map(({ id, uuid, createdAt, status, tenant_id }) => ({
    id,
    uuid,
    createdAt,
    status,
    tenant_id
  })) : [];

  console.log(bonus)

  return {
    columns: [
      { Header: "NumId", accessor: "id", width: "45%", align: "left" },
      { Header: "uuid", accessor: "uuid", width: "10%", align: "left" },
      { Header: "createdAt", accessor: "createdAt", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: rows,
  };
}
